import './TimingTile.scss';
import { useState } from 'react';
import { parseISO, intervalToDuration } from "date-fns";
import differenceInMilliseconds from 'date-fns/esm/fp/differenceInMilliseconds';
import useInterval from '../../../services/UseInterval';

interface TimingTileProps {
  startTime: string | null;
  raceDayNumber: number | null;
}

function TimingTile(props: TimingTileProps) {
  const [formattedTimeSinceStart, setFormattedTimeSinceStart] = useState(""); 
  const [formattedTimeToFinish, setFormattedTimeToFinish] = useState(""); 
  const [finishString, setFinishString] = useState(""); 

  const _updateIntervalInMs = 1000; 

  useInterval (() => {
    calculateTimeSinceStart();
    calculateTimeToFinish();
  }, _updateIntervalInMs)

  const calculateTimeSinceStart = () => {
    if(!props.startTime) {
      return null;
    }
    const parsedTime = parseISO(props.startTime);
    const duration = intervalToDuration({
      start: parsedTime,
      end: new Date()
    });
    const timeSinceStartString = padTo2Digits(duration.hours) + ":" + padTo2Digits(duration.minutes) + ":" + padTo2Digits(duration.seconds);
    setFormattedTimeSinceStart(timeSinceStartString);
  }

  const calculateTimeToFinish = () => {
    const finishTime = new Date();
    if(props.raceDayNumber === 8) { 
      finishTime.setUTCHours(13); // 15:30 SA time
      finishTime.setUTCMinutes(30);
    } else {
      finishTime.setUTCHours(15); // 17:00 SA time
      finishTime.setUTCMinutes(0);
    }
    finishTime.setUTCSeconds(0);
    finishTime.setUTCMilliseconds(0);
    const msToFinish = differenceInMilliseconds(new Date(), finishTime);
    let interval = null;
    if(msToFinish < 0) {
      setFinishString(' past end of day');
      interval = {
        start: finishTime,
        end: new Date()
      }
    } 
    else {
      setFinishString(' to end of day');
      interval = {
        start: new Date(),
        end: finishTime
      }
    }
    const duration = intervalToDuration(interval);
    const timeToFinishString = padTo2Digits(duration.hours) + ":" + padTo2Digits(duration.minutes) + ":" + padTo2Digits(duration.seconds);
    setFormattedTimeToFinish(timeToFinishString);
  }

  return (
    <div className="general-tile -time" tabIndex={0}>
      <div className='c-tile-title'>Driving time</div>
      { formattedTimeSinceStart ? 
        (<div className='c-tile-value'>{formattedTimeSinceStart}</div>) 
        : 
        (<div className='c-time-since-start -placeholder' />)
      }
      <div className="c-time-to-finish">
        { formattedTimeToFinish ? 
          formattedTimeToFinish + finishString 
          : 
          <div className="-placeholder"></div>
        } 
      </div>
    </div>
  );
}

const padTo2Digits = (num: number | undefined) => {
  if(!num) {
    return '00';
  }
  return num.toString().padStart(2, '0');
}

export default TimingTile;
