import './DriverTile.scss';
import ruben from "../../../assets/images/Ruben.png"
import ronan from "../../../assets/images/Ronan.png"
import gilles from "../../../assets/images/Gilles.png"
import Timestamp from '../../Timestamp/Timestamp';
import OfflinePlaceholder from '../../OfflinePlaceholder/OfflinePlaceholder';

interface DriverProps {
  lastUpdate?: string;
  driver?: string;
  visible?: boolean;
}

function DriverTile(props: DriverProps) {
  const baseUrl = "https://www.solarteam.be/en/sasol-solar-challenge-pilots/";

  const isTileDataLoading = () => {
    return props.visible === undefined 
      && props.driver === undefined;
  }

  const isTileOffline = () => {
    return !props.visible && !isTileDataLoading();
  }

  const getTemplate = () => {
    if (isTileDataLoading()) {
      return <>
        <div className="c-tile-value -placeholder"/>
        <div className="-cta">
          <div className="c-base-text -placeholder" />
        </div>
      </>;
    }
    if (props.driver && props.visible) {
      return <>
        <div className="c-tile-value">{props.driver}</div> 
        <div className="-cta">
          <a href={baseUrl + props.driver} target="_blank" rel="noopener noreferrer">
            <span className="-underline-on-hover">View pilot trivia</span> →
          </a>
        </div>
        <div className="-headshot-container">
          {props.driver ? <img className="-headshot" src={getHeadshot(props.driver)} alt={"Picture of pilot " + props.driver} /> : null }
        </div>
      </>;
    }
    return <>
      <div className="c-tile-value">-</div> 
    </>;
  }

  return (
    <div className="general-tile -driver" tabIndex={0}>
      <div className="c-tile-title">Current Driver</div>
      { isTileOffline() ? <OfflinePlaceholder /> : <Timestamp timestamp={props.lastUpdate} />}
      { getTemplate() }
    </div>
  );
}

const getHeadshot = (driver: string) => {
  switch(driver) {
    case "ruben":
      return ruben;
    case "gilles":
      return gilles;
    case "ronan":
      return ronan;
    case "casper":
      return ruben;
  }
}

export default DriverTile;
