import './MapTile.scss';
import L from 'leaflet';
import { Circle, MapContainer, Marker, Polyline, TileLayer, useMap } from 'react-leaflet';
import MarkerImage from '../../../assets/images/Marker.png';
import { day1 } from '../../../assets/data/day1';
import { day2 } from '../../../assets/data/day2';
import { day3 } from '../../../assets/data/day3';
import { day4 } from '../../../assets/data/day4';
import { day5 } from '../../../assets/data/day5';
import { day6 } from '../../../assets/data/day6';
import { day7 } from '../../../assets/data/day7';
import { day8 } from '../../../assets/data/day8';
import { fullrace } from '../../../assets/data/fullrace';
import Timestamp from '../../Timestamp/Timestamp';
import { useEffect, useState } from 'react';
import OfflinePlaceholder from '../../OfflinePlaceholder/OfflinePlaceholder';


interface MapTileProps {
  raceDayNumber: number | null;
  lastUpdate?: string;
  xCoordinate?: number;
  yCoordinate?: number;
  visible?: boolean;
  showFullRoute: boolean;
}

interface MapTileContentProps {
  forwardedProps: MapTileProps;
  isTileDataLoading: boolean;
  isTileOffline: boolean;
}

const raceStart: [number,number] = [-26.26003838,28.31417656];
const raceFinish: [number,number] = [-33.90272865359881,18.42304426470219];

function MapTile (props: MapTileProps) {

  const isTileDataLoading = () => {
    return !props.showFullRoute && (!props.xCoordinate && !props.yCoordinate && props.visible === undefined);
  }

  const isTileOffline = () => {
    return !props.visible && !isTileDataLoading();
  }

  return (
    <MapContainer 
      className="-map"
      bounds={[[-35.093460, 17.575438], [-24.889597, 29.001442]]} 
      scrollWheelZoom={false}
      minZoom={5}
      zoomSnap={0}
      maxBounds={[[-21.961960, 32.948208], [-36.662189, 15.462916]]} 
    >
      <MapTileContent 
        forwardedProps={props}
        isTileOffline={isTileOffline()}
        isTileDataLoading={isTileDataLoading()}
      />
    </MapContainer>
  );
}

function MapTileContent (props: MapTileContentProps) {
  const [route, setRoute] = useState<[number, number][] | null> (null);
  const [start, setStart] = useState<[number, number]> (raceStart);
  const [finish, setFinish] = useState<[number, number]> (raceFinish);

  const map = useMap();

  const routeOptions = { color: '#181310', weight: 4 }
  const fullRouteOptions = { color: '#181310', weight: 2, dashArray: "2 4" }
  const startFinishcarMarkerOptions = { color: '#181310', fillOpacity: 1, weight: 8 }

  const iconSize = 40;
  var carMarker = L.icon({
    iconUrl: MarkerImage,
    iconSize: [iconSize, iconSize], // size of the icon
    iconAnchor: [iconSize/2, iconSize/2], // point of the icon which will correspond to carMarker's location
  });

  const getRoute = (raceDayNumber: number | null) => {
    switch(raceDayNumber) {
      case 1:
        return day1;
      case 2:
        return day2;
      case 3:
        return day3;
      case 4:
        return day4;
      case 5:
        return day5;
      case 6:
        return day6;
      case 7:
        return day7;
      case 8:
        return day8;
    }
  }

  useEffect(() => {
    const routeVar = getRoute(props.forwardedProps.raceDayNumber);
    if(routeVar) {
      setRoute(routeVar);
      setStart(routeVar[0]);
      setFinish(routeVar[routeVar.length - 1]);
      if(props.forwardedProps.showFullRoute) {
        return;
      }
      else if(props.forwardedProps.raceDayNumber === 6) { // custom overwrite want heeft een buikje
        map.fitBounds(
          [routeVar[0], [-34.469108, 25.567008]], 
          { padding: [40, 40] }
        ); 
      }
      else if(props.forwardedProps.raceDayNumber === 5) { // full blind
        map.fitBounds(
          [[-32.058105, 24.706325], [-33.501293, 23.231410]], 
          { padding: [40, 40] }
        ); 
      }
      else {
        map.fitBounds(
          [routeVar[0], routeVar[routeVar.length - 1]], 
          { padding: [40, 40] }
        );
      }
    }
  }, [props.forwardedProps.raceDayNumber]);

  return (
    <>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
      />

      { route ? 
        <>
          <Circle center={start} pathOptions={ startFinishcarMarkerOptions } />
          <Circle center={finish} pathOptions={ startFinishcarMarkerOptions } />
          <Polyline pathOptions={routeOptions} positions={route} /> 
        </>
        : 
        null
      }

      { props.forwardedProps.showFullRoute ? 
        <>
          <Circle center={start} pathOptions={ startFinishcarMarkerOptions } />
          <Circle center={finish} pathOptions={ startFinishcarMarkerOptions } />
          <Polyline pathOptions={fullRouteOptions} positions={fullrace} /> 
        </>
        : 
        null
      }

      { !props.isTileOffline 
        && !props.isTileDataLoading 
        && props.forwardedProps.xCoordinate !== undefined 
        && props.forwardedProps.yCoordinate !== undefined
      ? 
        <Marker
          position={[props.forwardedProps.xCoordinate, props.forwardedProps.yCoordinate]} 
          icon={ carMarker } 
          /> 
        : 
        null
      }
      
      { props.forwardedProps.showFullRoute ? 
        null 
        : 
        (
          <div className='leaflet-top leaflet-right c-timestamp'>
            { props.isTileOffline ? 
              <OfflinePlaceholder /> 
              :  
              <Timestamp timestamp={props.forwardedProps.lastUpdate} /> 
            }
          </div>
        )
      }

      <div className="leaflet-bottom leaflet-right c-attribution" >
        Map by <a href="https://stadiamaps.com/">Stadia Maps</a>, <a href="https://openmaptiles.org/">OpenMapTiles</a>&nbsp;&&nbsp;<a href="http://openstreetmap.org">OpenStreetMap</a>
      </div>
    </>
  )
}

export default MapTile;
