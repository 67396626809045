import "./DrivingTileLayout.scss";

import { TwitterTimelineEmbed } from "react-twitter-embed";
import { DriverDto } from "../../../models/DriverDto";
import { LocationDto } from "../../../models/LocationDto";
import { MetricsDto } from "../../../models/MetricsDto";
import { RankingDto } from "../../../models/RankingDto";
import AltitudeTile from "../../Tiles/AltitudeTile/AltitudeTile";
import DailyRecapTile from "../../Tiles/DailyRecapTile/DailyRecapTile";
import DistanceTile from "../../Tiles/DistanceTile/DistanceTile";
import DriverTile from "../../Tiles/DriverTile/DriverTile";
import LoopsTile from "../../Tiles/LoopsTile/LoopsTile";
import MapTile from "../../Tiles/MapTile/MapTile";
import RankingTile from "../../Tiles/RankingTile/RankingTile";
import SolarTile from "../../Tiles/SolarTile/SolarTile";
import TemperatureTile from "../../Tiles/TemperatureTile/TemperatureTile";
import TimingTile from "../../Tiles/TimingTile/TimingTile";
import WindTile from "../../Tiles/WindTile/WindTile";

interface DrivingTileLayoutProps {
    raceDayNumber: number | null;
    location: LocationDto | null;
    startTime: string | null;
    ranking: RankingDto | null;
    metrics: MetricsDto | null;
    driver: DriverDto | null;
}

function DrivingTileLayout(props: DrivingTileLayoutProps) {
  return (
    <div className="c-dashboard c-driving-tile-layout">
        <p className="c-disclaimer">Data can fluctuate and will be shown at a time offset to avoid a strategic advantage for other teams.</p>
        <div className="c-tiles">
            <>
                <MapTile
                    raceDayNumber={props.raceDayNumber}
                    lastUpdate={props.location?.lastUpdate}
                    xCoordinate={props.location?.xCoordinate.value}
                    yCoordinate={props.location?.yCoordinate.value}
                    visible={props.location?.xCoordinate.visible && props.location?.yCoordinate.visible }
                    showFullRoute={false}
                />
                <TimingTile
                    startTime={props.startTime}
                    raceDayNumber={props.raceDayNumber}  
                />
                <LoopsTile
                    lastUpdate={props.location?.lastUpdate}
                    loops={props.location?.loops.value}
                    visible={props.location?.loops.visible}
                />
                <DistanceTile
                    lastUpdate={props.location?.lastUpdate}
                    distance={props.location?.distanceDriven?.value}
                    distanceVisible={props.location?.distanceDriven?.visible}
                    odo={props.location?.Odo?.value}
                    odoVisible={props.location?.Odo?.visible}
                />
                <RankingTile
                    lastUpdate={props.ranking?.lastUpdate}
                    ranking={props.ranking?.ranking}
                    visible={props.ranking?.visible}
                />
                <SolarTile
                    lastUpdate={props.metrics?.lastUpdate}
                    solarPower={props.metrics?.solarPower.value}
                    visible={props.metrics?.solarPower.visible}
                />
                <TemperatureTile
                    lastUpdate={props.metrics?.lastUpdate}
                    temperature={props.metrics?.temperature.value}
                    visible={props.metrics?.temperature.visible}
                />
                <WindTile
                    lastUpdate={props.metrics?.lastUpdate}
                    windSpeed={props.metrics?.windSpeed.value}
                    visible={props.metrics?.windSpeed.visible}
                />
                <AltitudeTile
                    lastUpdate={props.location?.lastUpdate}
                    altitude={props.location?.altitude.value}
                    visible={props.location?.altitude.visible}
                />
                <DriverTile
                    lastUpdate={props.driver?.lastUpdate}
                    driver={props.driver?.driver}
                    visible={props.driver?.visible}
                />

                <div className='twitter' tabIndex={0}>
                    <TwitterTimelineEmbed sourceType="profile" screenName="solarteam_be" options={{height: 500}} />
                </div>
                <DailyRecapTile />
            </>
        </div>
    </div>
  ); 
}
export default DrivingTileLayout;
