import GeneralTile from '../GeneralTile/GeneralTile';

interface TemperatureTileProps {
    lastUpdate?: string;
    temperature?: number;
    visible?: boolean;
}

function TemperatureTile(props: TemperatureTileProps) {
  return (
    <GeneralTile
        className="-temp" 
        label="Outside temp"
        timestamp={props.lastUpdate}
        value={props.temperature}
        unit="°C"
        visible={props.visible}
    />
  ); 
}
export default TemperatureTile;
