import './Timestamp.scss';
import { useState } from 'react';
import { parseISO, format, intervalToDuration, isBefore } from "date-fns";
import useInterval from '../../services/UseInterval';

interface TimeStampProps {
  timestamp?: string;
}

function TimeStamp(props: TimeStampProps) {
  const [durationSince, setDurationSince] = useState <Duration | null> (null); 
  const [formattedTimeSince, setFormattedTimeSince] = useState <string|null>(null); 
  const [formattedTimestamp, setFormattedTimestamp] = useState(""); 

  const _recentlyUpdatedTimeDiffInMin = 5;
  const _updateIntervalInMs = 1000; 

  useInterval (() => {
    calculateTimeDifference();
  }, _updateIntervalInMs)

  const calculateTimeDifference = () => {
    if(!props.timestamp) {
      return;
    }

    const parsedTime = parseISO(props.timestamp);
    const now = new Date();
    if(isBefore(parsedTime, now)) {
      const duration = intervalToDuration({
        start: parsedTime,
        end: now
      });
      setDurationSince(duration);
        setFormattedTimeSince(formatTimeDiff(durationSince));
        setFormattedTimestamp(format(parsedTime, 'EEE dd/MM - HH:mm'));
    }
  }

  if (props.timestamp && durationSince && formattedTimeSince) {
    return (
      <span title={"Updated: " + formattedTimestamp}>
        <span className='c-tile-timestamp'>{formattedTimeSince}</span>
        <>
          { recentlyUpdated(durationSince, _recentlyUpdatedTimeDiffInMin) ? 
            <span className="-updated" /> 
            : null
          }
        </>
      </span>
    )
  }
  return <div className='c-timestamp-placeholder' />;
}

const recentlyUpdated = (duration: Duration | null, timediff: number) => {
  if( duration !== null 
    && duration.days !== undefined 
    && duration.hours !== undefined 
    && duration.minutes !== undefined) {
      return duration.days === 0 
        && duration.hours === 0 
        && duration.minutes <= timediff;
  }
  return null;
}


const formatTimeDiff = (duration: Duration | null): string | null => {
  if(!duration) {
    return null;
  }
  else if (duration.days && duration.days > 0) {
    return duration.days + " day" + (duration.days === 1 ? "" : "s");
  }
  else if (duration.hours && duration.hours > 0) {
    return duration.hours + " hr" + (duration.hours === 1 ? "" : "s");
  }
  else if (duration.minutes && duration.minutes > 0) {
    return duration.minutes + " min" + (duration.hours === 1 ? "" : "s");
  }
  else if (duration.seconds && duration.seconds > 0) {
    return "Now";
  }
  return null;
}

export default TimeStamp;
