export const fullrace: [number, number][] = [
    [-26.26003838,28.31417656],
    [-26.25852203,28.32229424],
    [-26.25276566,28.30348206],
    [-26.25774765,28.17620468],
    [-26.2483139,28.16322517],
    [-26.25164032,28.1419239],
    [-26.24551773,28.1246109],
    [-26.25832748,28.11757469],
    [-26.26649666,28.09722328],
    [-26.27648544,28.10435486],
    [-26.28732681,28.10205841],
    [-26.34908485,28.10132217],
    [-26.45059204,28.07216263],
    [-26.54246712,28.00872993],
    [-26.57438469,27.99936295],
    [-26.63640594,27.95176888],
    [-26.67224121,27.90691185],
    [-26.72426796,27.89921188],
    [-26.75776291,27.87119102],
    [-26.78494835,27.85898209],
    [-26.78736496,27.84176254],
    [-26.79582214,27.84383392],
    [-26.80313301,27.83081627],
    [-26.79982376,27.82876205],
    [-26.79439354,27.82414627],
    [-26.80027771,27.82912254],
    [-26.80000687,27.83165169],
    [-26.78778076,27.82135773],
    [-26.83156776,27.74501801],
    [-26.84976578,27.62832069],
    [-26.84639931,27.6294117],
    [-26.8495369,27.62828827],
    [-26.84562302,27.70497322],
    [-26.80009079,27.80178261],
    [-26.78546143000001,27.82814789],
    [-26.80110931,27.83345222],
    [-26.80068207,27.83561516],
    [-26.80312729,27.83081627],
    [-26.79990959,27.82881546],
    [-26.79495239,27.82431602],
    [-26.79546678678449,27.82466600146526],
    [-26.80365181,27.8299675],
    [-26.80003738,27.83164215],
    [-26.78761101,27.82119751],
    [-26.83185768,27.74430466],
    [-26.84867287,27.64789391],
    [-26.88808632,27.62298583999999],
    [-27.02105331,27.56159019],
    [-27.16000938,27.51375771],
    [-27.26329231,27.48918343],
    [-27.3533287,27.42555237],
    [-27.43400574,27.36903954],
    [-27.52654076,27.30491447],
    [-27.61973381,27.25619888],
    [-27.62247276,27.24294472],
    [-27.61795807,27.23506546],
    [-27.62793922,27.2330761],
    [-27.63218307,27.23525429],
    [-27.63249207,27.23343658],
    [-27.61821175,27.23428535],
    [-27.62082481,27.23681259],
    [-27.6224823,27.25679587999999],
    [-27.64134026,27.24767685],
    [-27.66958809,27.25346184],
    [-27.69208336000001,27.23854065],
    [-27.78097343,27.22161674],
    [-27.91864586,27.2039299],
    [-27.9687748,27.188694],
    [-28.02531242,27.16880989],
    [-28.0967617,27.13277054],
    [-28.18249893,27.10393333],
    [-28.24753952,27.08822441],
    [-28.28333473,27.06467819],
    [-28.34384346,27.06507492],
    [-28.43886948,27.03378868],
    [-28.47202301,27.02576256],
    [-28.49887657,26.998106],
    [-28.50978279,27.00198746],
    [-28.51597214,27.01151657],
    [-28.51629448,27.01297951],
    [-28.51584053,27.01655388],
    [-28.49423266192287,27.03463849418811],
    [-28.50750351,27.00129889999999],
    [-28.514534,27.00541115],
    [-28.51629448,27.01297951],
    [-28.51584053,27.01655388],
    [-28.49423266192287,27.03463849418811],
    [-28.49897946664729,27.04966329836889],
    [-28.49783386103752,27.0940431984729],
    [-28.49905794523405,27.08930889927177],
    [-28.4978763851219,27.04543819683522],
    [-28.49830437,27.00340462],
    [-28.51397514,27.0045414],
    [-28.51620483,27.01614761],
    [-28.49494171,27.03408241],
    [-28.49885368,26.99929047],
    [-28.50584602,26.99484444],
    [-28.56507874,26.95867157],
    [-28.63294029,26.90169334],
    [-28.71142769,26.78243256],
    [-28.79855347,26.69106102],
    [-28.84470749,26.59391594],
    [-28.89251518,26.48031235],
    [-28.95690536,26.37781334],
    [-28.98157692,26.28102684],
    [-29.02882576,26.24793053],
    [-29.05505943,26.20817757],
    [-29.07199669,26.18433189],
    [-29.09138489,26.17256546],
    [-29.10378838,26.18323135],
    [-29.1026783,26.18468285000001],
    [-29.09196472,26.17195702],
    [-29.12548828,26.16324043],
    [-29.19256973,26.19334984],
    [-29.30490494,26.1626873],
    [-29.44158745,26.11413383],
    [-29.54177666,26.06643677],
    [-29.66721535,25.98530197],
    [-29.77946281,25.94944191],
    [-29.88855362,25.8657608],
    [-30.01925087,25.80069351],
    [-30.03565025,25.78978539],
    [-30.03559303,25.77843285],
    [-30.02827263,25.76277733],
    [-30.02128029,25.76177025],
    [-30.00023651,25.72905731],
    [-29.94627762,25.69404411],
    [-29.9198494,25.67661858],
    [-29.87258911,25.59662628],
    [-29.85352707,25.55985832],
    [-29.80558777,25.4980793],
    [-29.83163452,25.53283882],
    [-29.87070465,25.58606529],
    [-29.91880226,25.67523193],
    [-29.94164467,25.69032478],
    [-29.98788071,25.72005272],
    [-30.02198029,25.76246643],
    [-30.029459,25.7679081],
    [-30.03242874,25.78112411],
    [-30.0319690495446,25.78427899234176],
    [-30.05971909,25.78398705],
    [-30.19713974,25.72507668],
    [-30.26778793,25.72994232],
    [-30.35661697,25.68850899],
    [-30.44203568,25.62209129],
    [-30.5335865,25.53440475],
    [-30.55205727,25.49588966],
    [-30.55872345,25.49713326],
    [-30.58878326,25.49329376],
    [-30.59617615,25.50139809],
    [-30.59320641,25.49747276],
    [-30.58715438999999,25.48957253],
    [-30.55475807,25.49687195],
    [-30.55311966,25.49154854],
    [-30.59152412,25.40337563],
    [-30.61994934000001,25.35148621],
    [-30.62279701,25.26906013],
    [-30.69893074,25.12884903],
    [-30.73641586,25.08106422],
    [-30.82522964,24.9466629],
    [-30.90521049,24.80237198],
    [-30.95224953,24.68479156],
    [-31.02497673,24.54038811],
    [-31.072649,24.43575287],
    [-31.07365799,24.42983627],
    [-31.08474922,24.45816994],
    [-31.13812447,24.51301956],
    [-31.18129539,24.61470795],
    [-31.25807953,24.75318527],
    [-31.29992867,24.86198807],
    [-31.30048943,24.91449547],
    [-31.32023048,24.92467117],
    [-31.32505035,24.93508148],
    [-31.33074188,24.94369316],
    [-31.33160019,24.97197533],
    [-31.35927773,25.01447868],
    [-31.37574387,25.03250313],
    [-31.38302803,25.03243064999999],
    [-31.46525764,25.01835251],
    [-31.48138237,25.00984192],
    [-31.48806763,25.00772667],
    [-31.49129868,25.00329018],
    [-31.49045760555849,25.00204435396466],
    [-31.4912262,25.00326729],
    [-31.48326492,25.00897598],
    [-31.4910202,25.01505089],
    [-31.51698494,25.01907921],
    [-31.56184959,25.06567001000001],
    [-31.59929848,25.09478188],
    [-31.52331543,25.02843475],
    [-31.51461411,25.01696014],
    [-31.51798058,25.01662254],
    [-31.50244141,25.02223587],
    [-31.48951912,25.00735283],
    [-31.49116516,25.00219917],
    [-31.49111748,25.0021534],
    [-31.48974037,25.00720406],
    [-31.48959923,25.01409531],
    [-31.54513931,25.01132393],
    [-31.65260506,24.979105],
    [-31.75306892,24.9297657],
    [-31.78475189,24.8975544],
    [-31.80921555,24.87936783],
    [-31.81930923,24.85837936],
    [-31.83143043999999,24.85622215],
    [-31.83868217,24.86364555],
    [-31.84419441,24.8711319],
    [-31.84518814,24.87978745],
    [-31.85489845,24.87110138],
    [-31.88056946,24.82232285],
    [-31.93367386,24.74052048],
    [-31.96096611,24.72589874],
    [-31.96959686,24.71614456],
    [-32.00777435,24.65660477],
    [-32.05633545,24.61235809],
    [-32.09653091,24.60454559],
    [-32.11384583,24.60842896],
    [-32.13401794,24.57921028000001],
    [-32.14839554,24.5772686],
    [-32.2337532,24.5348835],
    [-32.24827576,24.53752708],
    [-32.24673462,24.52749061999999],
    [-32.25814438,24.53835678],
    [-32.27151871,24.54619217],
    [-32.28865814,24.55908394],
    [-32.33220291,24.56837654],
    [-32.39486694,24.61149216],
    [-32.50071716,24.6524334],
    [-32.63406754,24.68942451],
    [-32.68005753,24.69246483],
    [-32.77701569,24.72563171],
    [-32.80284882,24.72245026],
    [-32.84436035,24.71408272],
    [-32.84942245,24.72284698],
    [-32.85474777,24.73267555],
    [-32.87731934,24.71028519],
    [-32.88778687,24.70307731999999],
    [-32.92599869,24.67413902],
    [-32.94260025,24.667799],
    [-32.97187805,24.69478416],
    [-33.04020309,24.83461189],
    [-33.09775543,24.86838722],
    [-33.19641876,24.84692001],
    [-33.23544693,24.83552742],
    [-33.26693726,24.84010315],
    [-33.30112839,24.83378792],
    [-33.34140396,24.90697479],
    [-33.34567642,24.93078232],
    [-33.36452484,24.96146965],
    [-33.36679459,25.01761055],
    [-33.3719635,25.03404808],
    [-33.37419128,25.09315109],
    [-33.41677856,25.19294167],
    [-33.43893814,25.27292633],
    [-33.40290833,25.35449028],
    [-33.39861298,25.40599632],
    [-33.39780426,25.43982315],
    [-33.39976883,25.4394989],
    [-33.40459061,25.37094307],
    [-33.40785599,25.34201622],
    [-33.43918228,25.27317619],
    [-33.51589966,25.37523270000001],
    [-33.51189041,25.39317703],
    [-33.43071365,25.44301033],
    [-33.42609787,25.44215775],
    [-33.40050888,25.44423294],
    [-33.39764279471204,25.43885609949776],
    [-33.40080643,25.44659996],
    [-33.42884445,25.44320107],
    [-33.47328186,25.41581535],
    [-33.52285004,25.38971138],
    [-33.5455246,25.41933632],
    [-33.55839539,25.42448616],
    [-33.57602692,25.43188095],
    [-33.58995438,25.42804718],
    [-33.67159653,25.45912361],
    [-33.70478058,25.45502663],
    [-33.75016785,25.42973518],
    [-33.77892303,25.43319321],
    [-33.7817421,25.42704582],
    [-33.77262878,25.40705872],
    [-33.76337433,25.39457321],
    [-33.7775116,25.37665367],
    [-33.78766632,25.37538147],
    [-33.83227539,25.33799744],
    [-33.876091,25.3109169],
    [-33.88739395,25.29773521],
    [-33.90048599,25.27641295999999],
    [-33.90715027,25.25030327],
    [-33.90993118,25.22887611000001],
    [-33.89665604,25.15742683],
    [-33.90317917,25.10845947],
    [-33.90345001,25.08127022],
    [-33.91923141,25.06539917],
    [-33.93481064,25.00444221],
    [-33.96212006,24.95140648],
    [-34.01119995,24.88819313],
    [-34.01883275566622,24.89054446687861],
    [-34.01544571,24.88124275],
    [-34.01911545,24.82301521],
    [-33.99542236,24.70735741],
    [-34.00937271,24.66209412],
    [-34.03186035,24.59555244],
    [-34.04055023,24.57897568],
    [-34.03430557,24.5521183],
    [-34.05497742,24.5056324],
    [-34.03530502,24.37729836],
    [-33.99636459,24.21641541],
    [-33.98029327,24.04768372],
    [-33.97481155,23.96049881],
    [-33.9663887,23.88874245],
    [-33.97427368,23.81015015],
    [-33.96947861,23.67836761],
    [-33.93973923,23.61431122],
    [-33.93878937,23.55129051],
    [-33.93455505,23.49799728],
    [-33.94517136,23.48638344],
    [-33.96578217,23.46124268],
    [-33.96609879,23.44946861],
    [-33.96869278,23.44122314],
    [-33.97034073,23.43335342],
    [-33.98443985,23.43862724],
    [-33.99484253,23.43477631],
    [-34.00366211,23.41979218],
    [-34.005867,23.40741348],
    [-34.00269318,23.39808273],
    [-34.04344177,23.36749458],
    [-34.04530716,23.36229515],
    [-34.05039215,23.35621834],
    [-34.05357742,23.33970451],
    [-34.04428482,23.31354904],
    [-34.03726578,23.2283802],
    [-34.03192139,23.189291],
    [-34.0270195,23.1749649],
    [-34.02490234,23.16144562],
    [-34.03549194,23.15716553],
    [-34.03488922,23.12679291],
    [-34.04152679,23.11686325],
    [-34.0426445,23.106884],
    [-34.04491806,23.09335327],
    [-34.03970337,23.06760216],
    [-34.0381813,23.06274796],
    [-34.0358696,23.05180359],
    [-34.03497241346543,23.04329956833648],
    [-34.03645706,23.03219414],
    [-34.038517,23.01362419],
    [-34.02913666,23.00510216],
    [-34.02729797,22.99902344],
    [-34.03323746,22.99040604],
    [-34.03837967,22.9761467],
    [-34.03190231,22.96854019],
    [-34.02891922,22.94594765],
    [-34.03684616,22.92770767],
    [-34.02666855,22.88078308],
    [-34.01031876,22.7911396],
    [-34.00988007,22.78073692],
    [-34.01003647,22.78067207],
    [-34.01003647,22.78067207],
    [-34.00988007,22.78022003],
    [-34.00937271,22.78013992],
    [-34.00332642,22.72575951],
    [-34.00104141,22.68524551],
    [-34.0002594,22.66352081],
    [-33.99781418,22.61472321],
    [-33.9969902,22.59803772],
    [-33.99522018,22.57682419],
    [-33.99742508,22.56098175],
    [-33.99406433,22.55311203],
    [-33.98843765,22.55260849],
    [-33.99023819,22.54904747],
    [-33.98674393,22.54334641],
    [-33.99080276,22.53705215],
    [-33.99373627,22.52171135],
    [-33.98687744,22.5134182],
    [-33.99498367,22.4902935],
    [-34.00970078,22.4073143],
    [-34.02035522,22.36183548],
    [-34.03664017,22.291008],
    [-34.04429626,22.28562927],
    [-34.04443359,22.27481842],
    [-34.04989624,22.25099945],
    [-34.0578537,22.2160778],
    [-34.08428955,22.13862991],
    [-34.14946365,22.09875488],
    [-34.17421341,22.06791115],
    [-34.18162155,22.04758072],
    [-34.17807388,21.9268856],
    [-34.19726181,21.81246185],
    [-34.19002151,21.76540565],
    [-34.18617249,21.74433517],
    [-34.21126938,21.62030029],
    [-34.20317459,21.51985741],
    [-34.18633652,21.4364357],
    [-34.17031097,21.32497215],
    [-34.16160583,21.30513191],
    [-34.15428162,21.30243683],
    [-34.14689636,21.28269958],
    [-34.14353943,21.27927971],
    [-34.12805176,21.26391602],
    [-34.10666275,21.25420761],
    [-34.09487534,21.25257683],
    [-34.09176254,21.2543354],
    [-34.08945084,21.25782394],
    [-34.09013367,21.2579937],
    [-34.08917999,21.25592804],
    [-34.09157562,21.255373],
    [-34.09331894,21.25082588],
    [-34.09366989,21.21306992],
    [-34.11815643,21.13245392],
    [-34.1168251,21.09416199],
    [-34.09636307,21.05723381],
    [-34.08810425,21.01111031],
    [-34.08519363,20.99358559],
    [-34.09080887,20.97721863],
    [-34.11167145,20.93779182],
    [-34.11640549,20.85010338],
    [-34.10134125,20.79670143],
    [-34.10287857,20.75873756],
    [-34.09413147,20.72845459],
    [-34.06168747,20.61039925],
    [-34.05304337,20.58060074],
    [-34.04224777,20.56630516],
    [-34.03607559,20.51136017],
    [-34.02679443,20.44745064],
    [-34.05352402,20.42629623],
    [-34.09646225,20.37965202],
    [-34.105896,20.27758408],
    [-34.102005,20.18978119],
    [-34.08653259,20.09726143],
    [-34.10884476,20.06848717],
    [-34.13911057,20.02331924],
    [-34.14582443,19.9804554],
    [-34.15446472,19.9303627],
    [-34.14973068,19.90801239],
    [-34.164608,19.82790756],
    [-34.17267227,19.76752281],
    [-34.18577576,19.7023735],
    [-34.18186188,19.66936111],
    [-34.20173264,19.61584091],
    [-34.20452118,19.60036087],
    [-34.20721436,19.58590889],
    [-34.20722961,19.57353973],
    [-34.21567535,19.55748177],
    [-34.22506714,19.54581451],
    [-34.226017,19.52196884],
    [-34.22715759,19.51294327],
    [-34.22742462,19.50022125],
    [-34.22980499,19.48731804],
    [-34.22211075,19.44546127],
    [-34.22507477,19.43596458],
    [-34.22427368,19.43652916],
    [-34.22472382,19.43730736],
    [-34.22536469,19.43628502],
    [-34.22687149,19.43320465],
    [-34.22218704,19.40853119],
    [-34.21243668,19.3996067],
    [-34.18997192,19.40410995],
    [-34.17566681,19.41081619],
    [-34.16611862,19.41756439],
    [-34.1587944,19.44779778],
    [-34.15472794,19.46159363],
    [-34.14575958,19.49320412],
    [-34.13978577,19.50398827],
    [-34.15501022,19.4696331],
    [-34.15715408,19.45449638],
    [-34.16277313,19.43714333],
    [-34.17172241,19.41390038],
    [-34.18080139,19.40518188],
    [-34.19495392,19.40454865],
    [-34.21619797,19.40135384],
    [-34.22695541,19.43251801],
    [-34.22522736,19.43621063],
    [-34.22431307830665,19.43664998025357],
    [-34.22502907765163,19.43715914701668],
    [-34.22507477,19.43550873],
    [-34.22764206,19.42570686],
    [-34.2155838,19.40083313],
    [-34.20866013,19.38978958],
    [-34.2115097,19.32466507],
    [-34.22504044,19.29492569],
    [-34.22592163,19.27377129],
    [-34.2341156,19.2590847],
    [-34.23231125,19.23751068],
    [-34.23476028,19.19260597],
    [-34.22165298,19.18801308],
    [-34.21812057,19.18906593],
    [-34.222332,19.18147278],
    [-34.22401428,19.17341042],
    [-34.21758652,19.17306328],
    [-34.21376419,19.16664314],
    [-34.20965195,19.15721321],
    [-34.20584488,19.14781189],
    [-34.19867325,19.13188744],
    [-34.19185257,19.11203766],
    [-34.19204712,19.09812546],
    [-34.17882919,19.07699394],
    [-34.16259384,19.03059769],
    [-34.16479111,19.02249908],
    [-34.16672134,19.000597],
    [-34.15284348,18.96576881],
    [-34.15240479,18.93839645],
    [-34.15107346,18.93050003],
    [-34.14414978,18.92996025],
    [-34.13713074,18.93393898],
    [-34.12751389,18.93349838],
    [-34.12933731,18.93055344],
    [-34.13418198,18.92657471],
    [-34.11827087,18.87765503],
    [-34.09711838,18.84766769],
    [-34.0851059,18.83472824],
    [-34.05847168,18.79012489],
    [-33.90143500555664,18.42122029495178],
    [-33.90136719,18.42324257],
    [-33.90272865359881,18.42304426470219],
]
