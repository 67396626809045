export const day3: [number, number][] = [
    [-29.10497888176011,26.18180683679161],
    [-29.10383987000001,26.18334007],
    [-29.1026783,26.18468285000001],
    [-29.10207367,26.18424034],
    [-29.10145569,26.18356514],
    [-29.09179878,26.17248726],
    [-29.09196472,26.17195702],
    [-29.09351921,26.1692009],
    [-29.09662437,26.16687775],
    [-29.10132217,26.16506004],
    [-29.12548828,26.16324043],
    [-29.13125992000001,26.16490173],
    [-29.16413689,26.1808567],
    [-29.18728256,26.1931572],
    [-29.19256973,26.19334984],
    [-29.19746208,26.19209099],
    [-29.23259163000001,26.18212128],
    [-29.26877213,26.17235756],
    [-29.30490494,26.1626873],
    [-29.34108734,26.15304565],
    [-29.37349129,26.14437866],
    [-29.40793037,26.13186646],
    [-29.44158745,26.11413383],
    [-29.47680664,26.10066223],
    [-29.5105896,26.08771133],
    [-29.53063011,26.07474899],
    [-29.54177666,26.06643677],
    [-29.57318115,26.04370499],
    [-29.60395432,26.02218628],
    [-29.63532066,26.00024796],
    [-29.66721535,25.98530197],
    [-29.70053101,25.97424698],
    [-29.73616982,25.96366692],
    [-29.77212143,25.95296288],
    [-29.77946281,25.94944191],
    [-29.80240059,25.93060875],
    [-29.83111382,25.90686607],
    [-29.86175728,25.88319397],
    [-29.88855362,25.8657608],
    [-29.91998672,25.84558487],
    [-29.95356178,25.82805443],
    [-29.98469353,25.81508255],
    [-30.01925087,25.80069351],
    [-30.03517723,25.79445266999999],
    [-30.03761864000001,25.79462051],
    [-30.03852844,25.7942791],
    [-30.03565025,25.78978539],
    [-30.031353,25.78315163],
    [-30.03126010330566,25.78298355670266],
    [-30.03340529999999,25.7803154],
    [-30.03559303,25.77843285],
    [-30.03546524,25.77809143],
    [-30.02979279,25.76914787],
    [-30.02952385,25.76815414],
    [-30.02827263,25.76277733],
    [-30.02827835,25.7628231],
    [-30.02514267,25.76358986],
    [-30.02309418,25.76316833],
    [-30.02128029,25.76177025],
    [-30.0178051,25.75679588],
    [-30.00859451,25.74283791],
    [-30.00497055,25.7334938],
    [-30.00023651,25.72905731],
    [-29.97524452,25.71059799],
    [-29.97386169,25.71014023],
    [-29.95362473,25.70090866],
    [-29.94627762,25.69404411],
    [-29.93230629,25.68429947],
    [-29.92505264,25.68274117],
    [-29.92370605,25.68170547],
    [-29.9198494,25.67661858],
    [-29.89937019,25.64941597],
    [-29.89034271,25.63658524],
    [-29.88518143,25.61838341],
    [-29.87258911,25.59662628],
    [-29.87085533,25.58701134],
    [-29.86766052,25.57238197],
    [-29.86478424,25.56792831],
    [-29.85352707,25.55985832],
    [-29.8321476,25.53354645],
    [-29.81269073,25.50541687],
    [-29.80819893,25.49931335],
    [-29.80558777,25.4980793],
    [-29.80564499,25.49816132],
    [-29.80815697,25.49929047],
    [-29.81320953,25.50611305],
    [-29.83163452,25.53283882],
    [-29.85289001,25.55920601],
    [-29.86656952,25.57032394],
    [-29.86878777,25.5755024],
    [-29.87070465,25.58606529],
    [-29.87301064,25.59774971],
    [-29.88477707,25.61760139],
    [-29.89826965,25.64803505],
    [-29.91880226,25.67523193],
    [-29.9243679,25.68235779],
    [-29.92745209,25.68371582],
    [-29.93233871,25.68431854],
    [-29.94164467,25.69032478],
    [-29.9673481,25.70826149],
    [-29.9683609,25.70855141],
    [-29.97583961,25.71094704],
    [-29.98788071,25.72005272],
    [-29.99567604,25.72525977999999],
    [-30.00497055,25.73348808],
    [-30.01493454,25.75263596],
    [-30.02198029,25.76246643],
    [-30.02378845,25.76342964],
    [-30.02588081,25.76352882],
    [-30.02815628,25.76286316],
    [-30.029459,25.7679081],
    [-30.03005791,25.76975632],
    [-30.03548241,25.7781868],
    [-30.03539467,25.77857399],
    [-30.03242874,25.78112411],
    [-30.03090477,25.782444],
    [-30.03118511264601,25.7830638673164],
    [-30.03124266308772,25.78314712303274],
    [-30.0319690495446,25.78427899234176],
    [-30.03691292,25.79171753],
    [-30.03852844,25.79413414],
    [-30.03965759,25.79254532],
    [-30.05971909,25.78398705],
    [-30.09391975,25.76956367],
    [-30.12782478,25.75517464],
    [-30.16192245,25.73825264],
    [-30.19713974,25.72507668],
    [-30.22890282,25.72130203],
    [-30.2338562,25.72233772],
    [-30.26262665,25.73058319],
    [-30.26778793,25.72994232],
    [-30.29027176,25.72610474],
    [-30.29199409,25.72514153],
    [-30.32287979,25.70638275],
    [-30.35661697,25.68850899],
    [-30.38769531,25.67585182],
    [-30.4011364,25.67068481],
    [-30.4159832,25.65045166],
    [-30.44203568,25.62209129],
    [-30.47177505,25.59879684],
    [-30.50046158,25.57408905],
    [-30.52578926,25.54474449],
    [-30.5335865,25.53440475],
    [-30.53851128,25.51488304],
    [-30.54933739,25.4972744],
    [-30.55088615,25.49635696],
    [-30.55205727,25.49588966],
    [-30.55276871,25.49536705],
    [-30.55548477,25.49719429],
    [-30.55642891,25.49740982],
    [-30.55872345,25.49713326],
    [-30.58104706,25.4895401],
    [-30.58697891,25.48953819],
    [-30.58727646,25.48993683],
    [-30.58878326,25.49329376],
    [-30.59296036,25.49699211],
    [-30.59324074,25.49761963],
    [-30.59346008,25.49790573],
    [-30.59617615,25.50139809],
    [-30.59687989049021,25.50192375681254],
]
