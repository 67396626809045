import "./DailyRecapTile.scss";

function DailyRecapTile () {
  return (
    <div className='dail' tabIndex={0}>
      <div className="-cta">Click the icon below to view all videos ↓</div>
      <iframe
          src="https://www.youtube.com/embed/videoseries?list=PLSqBTpVRIusVVz4Glm01OIO7WYfL6TWpM" 
          title="Daily recap - Sasol Solar Challenge" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
      ></iframe>
  </div>
  ); 
}
export default DailyRecapTile;
