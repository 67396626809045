import './App.scss';
import Header from './components/Header/Header';
import { useEffect, useState } from 'react';
import { getStatusService, getRankingService, getStartTimeService, getMetricsService, getLocationService, getDriverService } from './services/Service';
import { StatusDto } from './models/StatusDto';
import { RankingDto } from './models/RankingDto';
import { StartTimeDto } from './models/StartTimeDto';
import { MetricsDto } from './models/MetricsDto';
import { LocationDto } from './models/LocationDto';
import { DriverDto } from './models/DriverDto';
import DrivingTileLayout from './components/TileLayouts/DrivingTileLayout/DrivingTileLayout';
import BeforeTileLayout from './components/TileLayouts/BeforeTileLayout/BeforeTileLayout';
import NightTileLayout from './components/TileLayouts/NightTileLayout/NightTileLayout';
import OfflineTileLayout from './components/TileLayouts/OfflineTileLayout/OfflineTileLayout';
import FinishedTileLayout from './components/TileLayouts/FinishedTileLayout/FinishedTileLayout';
import { utcToZonedTime } from 'date-fns-tz';
import useInterval from './services/UseInterval';

function App() {
  const [dashboardStatus, setDashboardStatus] = useState <string | null> (null);
  const [ranking, setRanking] = useState <RankingDto | null> (null); 
  const [startTime, setStartTime] = useState <string | null> (null); 
  const [metrics, setMetrics] = useState <MetricsDto | null> (null);
  const [location, setLocation] = useState <LocationDto | null> (null);
  const [driver, setDriver] = useState <DriverDto | null> (null);
  const [raceDayNumber, setRaceDayNumber] = useState <number | null> (null);

  const pollingTime = 30000;

  useEffect(() => {
    fetchData();
  }, [])

  useInterval (() => {
    fetchData();
  }, pollingTime);

  const fetchData = () => {
    const raceDay = calculateRaceDayNumber();
    setRaceDayNumber(raceDay);
    getStatusService().then((resultData: StatusDto) => {
      setDashboardStatus(resultData.status)
      if(resultData.status !== "offline") {
        getRankingService().then((resultData: RankingDto) => setRanking(resultData));
        getMetricsService().then((resultData: MetricsDto) => setMetrics(resultData));
        getLocationService().then((resultData: LocationDto) => setLocation(resultData));
        getDriverService().then((resultData: DriverDto) => setDriver(resultData));
        if(resultData.status === "night" && isAfternoon()) {
          // setStartTime(getStartTimeHardCoded(raceDay+1));
          getStartTimeService(raceDay+1).then((resultData: StartTimeDto) => setStartTime(resultData.startTime));
        } else {
          // setStartTime(getStartTimeHardCoded(raceDay));
          getStartTimeService(raceDay).then((resultData: StartTimeDto) => setStartTime(resultData.startTime));
        }
      }
    });
  }

  const getStartTimeHardCoded = (dayNumber: number): string => {
    switch(dayNumber) {
      case 1:
        return "2022-09-09T07:01:00Z";
      case 2:
        return "2022-09-10T06:00:00Z";
      case 3:
        return "2022-09-11T06:00:00Z";
      case 4:
        return "2022-09-12T06:00:00Z";
      case 5:
        return "2022-09-13T06:00:00Z";
      case 6:
        return "2022-09-14T06:00:00Z";
      case 7:
        return "2022-09-15T06:00:00Z";
      case 8:
        return "2022-09-16T06:00:00Z";
      default: 
        return "2022-09-09T06:00:00Z";
    }
  }

  const isAfternoon = () => {
    const utcHours = new Date().getUTCHours();
    if (utcHours > 10 && utcHours <= 21) {
      return true;
    }
    return false;
  }

  const calculateRaceDayNumber = (): number => {
    const zonedDate = utcToZonedTime(new Date(), 'Africa/Johannesburg');
    switch(zonedDate.getDate()) {
      case 8: // TODO: remove
        return 0;
      case 9:
        return 1;
      case 10: 
        return 2;
      case 11: 
        return 3;
      case 12: 
        return 4;
      case 13: 
        return 5;
      case 14:  
        return 6;
      case 15: 
        return 7;
      case 16: 
        return 8;
      default:
        return 1;
    }
  }

  function getTiles() {
    switch(dashboardStatus) {
      case "before":
        return <BeforeTileLayout />
      case "driving":
        return <DrivingTileLayout 
          raceDayNumber={raceDayNumber}
          location={location}
          startTime={startTime}
          ranking={ranking}
          metrics={metrics}
          driver={driver}
        />;
      case "night":
        return <NightTileLayout 
          raceDayNumber={raceDayNumber}
          ranking={ranking}
          location={location}
          startTime={startTime}
        />
      case "offline":
        return <OfflineTileLayout />
      case "finished":
        return <FinishedTileLayout ranking={ranking} />
      default: 
        return null;
    }
  }

  return (
    <>
        <Header raceDayNumber={raceDayNumber} />
        { getTiles() }
    </>
  );
}

export default App;
