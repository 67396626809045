import './RankingTile.scss';
import be from '../../../assets/flags/belgium.png';
import nl from '../../../assets/flags/netherlands.png';
import sa from '../../../assets/flags/south-africa.png';
import pl from '../../../assets/flags/placeholder.png';
import Timestamp from '../../Timestamp/Timestamp';
import OfflinePlaceholder from '../../OfflinePlaceholder/OfflinePlaceholder';

interface RankingProps {
  lastUpdate?: string;
  ranking?: string[];
  visible?: boolean;
}

function RankingTile(props: RankingProps) {
  const placeholderArray = [0,1,2];

  const isTileDataLoading = () => {
    return props.visible === undefined 
      && props.ranking === undefined;
  }

  const isTileOffline = () => {
    return !props.visible && !isTileDataLoading();
  }

  const getTemplate = () => {
    if (isTileDataLoading()) {
      return placeholderArray.map((index) => {
        return <tr key={index.toString()}>
          <td>
            <div className='c-ranking-list__number'>0{index+1}</div>
          </td>
          <td>
            <img className="c-ranking-list__flag" src={pl} alt="Unknown flag" />
          </td>
          <td className='c-ranking-list__team'>
            <div className='-teamname -placeholder'></div>
            <div className='-car -placeholder'></div>
          </td>
        </tr>;
      });
    }
    if (props.ranking && props.visible) {
      return props.ranking?.map((rank, index) => {
        return <tr key={rank}>
          <td>
            <div className='c-ranking-list__number'>0{index+1}</div>
          </td>
          {getTeam(rank)}
        </tr>
      });
    }
    return placeholderArray.map((index) => {
      return <tr key={index.toString()}>
        <td>
          <div className='c-ranking-list__number'>0{index+1}</div>
        </td>
        <td>
          <img className="c-ranking-list__flag" src={pl} alt="Unknown flag" />
        </td>
        <td className='c-ranking-list__team'>
          <div className='-teamname'>-</div>
        </td>
      </tr>;
    });
  }

  return (
    <div className="-ranking" tabIndex={0}>
      <div className='mb05'>
        <div className='c-tile-title'>Estimated total ranking</div>
        { isTileOffline() ? <OfflinePlaceholder /> : <Timestamp timestamp={props.lastUpdate} /> }
      </div>
      <div>
        <table className='c-ranking-list'>
          <tbody>
            { getTemplate() }
          </tbody>
        </table>
      </div>
      <div className='mt1'>
        <p>Estimation only. Official rankings <a href="https://www.solarchallenge.org.za/solar-challenge/overview" className='-cta'><span className="-underline-on-hover"> can be found here
          </span></a> or on the mobile app (
            <a href="https://apps.apple.com/za/app/sasol-solar-challenge/id1638534343" className='-cta'><span className="-underline-on-hover">ios</span></a>
            /
            <a href="https://play.google.com/store/apps/details?id=com.sasol.mobile.maui" className='-cta'><span className="-underline-on-hover">android</span></a>).</p>
      </div>
    </div>
  );
}

const getTeam = (teamString: string) => {
  var flag = pl; 
  var nationality = "Unknown";
  var team = "(Unknown)";
  var car = " ";
  switch(teamString) {
    case "agoria":
      flag = be;
      nationality = "Belgian";
      team = "Agoria Solar Team";
      car = "BluePoint Atlas";
      break;
    case "brunel":
      flag = nl;
      nationality = "Dutch";
      team = "Brunel Solar Team";
      car = "Nuna 11S";
      break;
    case "flair":
      flag = sa;
      nationality = "South African";
      team = "SolarFlair";
      car = "SolarFlair SP 400";
      break;
    case "tshw":
      flag = sa;
      nationality = "South African";
      team = "Tshwane University of Technology Solar Team";
      car = "SunChaser IV";
      break;
    case "free":
      flag = sa;
      nationality = "South African";
      team = "University of Free State Solar Team";
      car = "Lengau";
      break;
    case "seil":
      flag = sa;
      nationality = "South African";
      team = "Central University of Technology: Seilatsatsi";
      car = "Ntsu";
      break;
    case "genuine":
      flag = sa;
      nationality = "South African";
      team = "Genuine JV Solar Car Team";
      car = "Voltwagen";
      break;
    case "unichamps":
      flag = sa;
      nationality = "South African";
      team = "UniChamps Solar Car Team";
      car = "UniSolar";
      break;
    case "north":
      flag = sa;
      nationality = "South African";
      team = "North West University Solar Team";
      car = "Naledi 2.0";
      break;
    case "toyota":
      flag = sa;
      nationality = "South African";
      team = "Toyota Team Sonke";
      car = "Siyakude II";
      break;
  }
  return <>
    <td>
      <img className="c-ranking-list__flag" src={flag} alt={nationality + " flag"} />
    </td>
    <td className='c-ranking-list__team'>
      <div className='-teamname'>{team}</div>
      <div className='-car'>{car}</div>
    </td>
  </>
}

export default RankingTile;
