export const day8: [number, number][] = [
    [-34.08917999,21.25592804],
    [-34.09046173,21.25626945],
    [-34.0908432,21.2550354],
    [-34.09135818,21.25525665],
    [-34.09157562,21.255373],
    [-34.09200668,21.25334549],
    [-34.09277725,21.25201797],
    [-34.09348297,21.25123596],
    [-34.09331894,21.25082588],
    [-34.08905792,21.24504089],
    [-34.08811188,21.24172211],
    [-34.08810425,21.23818016],
    [-34.09366989,21.21306992],
    [-34.09429932,21.21190453],
    [-34.10282898,21.19830513],
    [-34.10668564,21.17439461],
    [-34.11815643,21.13245392],
    [-34.12545395,21.11215782],
    [-34.12535858,21.10742378],
    [-34.12390137,21.10297966],
    [-34.1168251,21.09416199],
    [-34.11420822,21.09109306],
    [-34.10895538,21.08493996],
    [-34.10297775,21.07757187],
    [-34.09636307,21.05723381],
    [-34.0927887,21.04750633],
    [-34.08996582,21.01451111],
    [-34.08981323,21.01408386],
    [-34.08810425,21.01111031],
    [-34.0845108,21.00736046],
    [-34.08358002,21.00474358],
    [-34.0837059,21.00168991],
    [-34.08519363,20.99358559],
    [-34.08519363,20.99358559],
    [-34.08643723,20.98289108],
    [-34.08747864,20.98091125],
    [-34.09080887,20.97721863],
    [-34.09207535,20.97563744],
    [-34.09700394,20.96396065],
    [-34.10414886,20.95759773],
    [-34.11167145,20.93779182],
    [-34.11317444,20.92383575],
    [-34.11457825,20.89035797],
    [-34.11834335,20.87372398],
    [-34.11640549,20.85010338],
    [-34.11310577,20.81682205],
    [-34.10824966,20.80762482],
    [-34.10195541,20.79955673],
    [-34.10134125,20.79670143],
    [-34.10179901,20.79378128],
    [-34.10603333,20.78377533],
    [-34.10614395,20.76746559],
    [-34.10287857,20.75873756],
    [-34.10424042,20.74474335],
    [-34.10318375,20.74118805],
    [-34.09425735,20.72889709],
    [-34.09413147,20.72845459],
    [-34.0868721,20.68475723],
    [-34.08955002,20.64498329],
    [-34.0881424,20.64158058],
    [-34.06168747,20.61039925],
    [-34.05730057,20.60483932],
    [-34.05661392,20.60190392],
    [-34.05737686,20.59533119],
    [-34.05304337,20.58060074],
    [-34.05107498,20.57788467],
    [-34.04846954,20.57534409],
    [-34.04351807,20.56997108],
    [-34.04224777,20.56630516],
    [-34.03747177,20.54209518],
    [-34.03621674,20.53431129],
    [-34.03797913,20.51780128],
    [-34.03607559,20.51136017],
    [-34.02565765,20.47564888],
    [-34.02348328,20.46632957],
    [-34.02478027,20.4538269],
    [-34.02679443,20.44745064],
    [-34.03237915,20.43795586],
    [-34.04067612,20.43189621],
    [-34.04304123,20.43100929],
    [-34.05352402,20.42629623],
    [-34.06592178,20.41516304],
    [-34.08377457,20.39884567],
    [-34.08879852,20.39034653],
    [-34.09646225,20.37965202],
    [-34.10533142,20.35865021],
    [-34.10766602,20.35240555],
    [-34.10598373,20.31606865],
    [-34.105896,20.27758408],
    [-34.10543823,20.23680305],
    [-34.09976578,20.21119499],
    [-34.10216141,20.19377136],
    [-34.102005,20.18978119],
    [-34.09725189,20.15451622],
    [-34.08958435,20.11137581],
    [-34.08642197,20.10234261],
    [-34.08653259,20.09726143],
    [-34.09012985,20.08335686],
    [-34.09225845,20.08053207],
    [-34.09900284,20.07588577],
    [-34.10884476,20.06848717],
    [-34.11149216,20.06399918],
    [-34.11914063,20.04165649],
    [-34.11965942,20.04096031],
    [-34.13911057,20.02331924],
    [-34.14042282,20.01968193],
    [-34.14144135,20.00865936],
    [-34.14390945,19.98394012],
    [-34.14582443,19.9804554],
    [-34.15343857,19.97070694],
    [-34.15744781,19.9650116],
    [-34.15838242,19.9613266],
    [-34.15446472,19.9303627],
    [-34.15340424,19.92390823],
    [-34.15124512,19.92016411],
    [-34.14970398,19.9081955],
    [-34.14973068,19.90801239],
    [-34.15019989,19.90680122],
    [-34.15175629,19.90416718],
    [-34.15745163,19.87089539],
    [-34.164608,19.82790756],
    [-34.17352676,19.78484726],
    [-34.17570114,19.77659607],
    [-34.17525101,19.77307892],
    [-34.17267227,19.76752281],
    [-34.17184448,19.76128387],
    [-34.17308426,19.75829124],
    [-34.18078995,19.74616432],
    [-34.18577576,19.7023735],
    [-34.1857605,19.70200157],
    [-34.18244553,19.68952751],
    [-34.1845665,19.68226624],
    [-34.18186188,19.66936111],
    [-34.18556595,19.66125107],
    [-34.19765091,19.64115524],
    [-34.19998932,19.62200165],
    [-34.20173264,19.61584091],
    [-34.20139694,19.61274147],
    [-34.2004776,19.60966682],
    [-34.2007103,19.60717201],
    [-34.20452118,19.60036087],
    [-34.20463562,19.59729767],
    [-34.20402145,19.59367561],
    [-34.20455551,19.59088326],
    [-34.20721436,19.58590889],
    [-34.20740891,19.58306122],
    [-34.20713043,19.58102036],
    [-34.20648193,19.57534027],
    [-34.20722961,19.57353973],
    [-34.21240616,19.56791115],
    [-34.21487427,19.56350327],
    [-34.21474075,19.55925941],
    [-34.21567535,19.55748177],
    [-34.21823883,19.55478668],
    [-34.22113037,19.55359077],
    [-34.22270203,19.55116081],
    [-34.22506714,19.54581451],
    [-34.22526169,19.54157257],
    [-34.22396088,19.53390312],
    [-34.22668076,19.52432632],
    [-34.226017,19.52196884],
    [-34.22381592,19.51913834],
    [-34.2234726,19.51728058],
    [-34.22404861,19.51547623],
    [-34.22715759,19.51294327],
    [-34.23033524,19.50664711],
    [-34.23049164,19.50457191],
    [-34.22959518,19.50271034],
    [-34.22742462,19.50022125],
    [-34.22660828,19.49773026],
    [-34.22682571,19.4954567],
    [-34.22947693,19.49054527],
    [-34.22980499,19.48731804],
    [-34.22521591,19.47434616],
    [-34.22438431,19.47070313],
    [-34.2233429,19.45108604],
    [-34.22211075,19.44546127],
    [-34.22301483,19.43775558],
    [-34.22370529,19.43650818],
    [-34.22463608,19.43578148],
    [-34.22507477,19.43596458],
    [-34.22520828,19.43635368],
    [-34.22472763,19.43637085],
    [-34.22445297,19.43630791],
    [-34.22427368,19.43652916],
    [-34.22435379,19.43679428],
    [-34.22446823,19.43700027],
    [-34.22450304776022,19.43694333746839],
    [-34.22472382,19.43730736],
    [-34.22492599,19.43728065],
    [-34.22529221,19.43676567],
    [-34.22549057,19.43668938],
    [-34.22536469,19.43628502],
    [-34.22503281,19.43567276],
    [-34.22529984,19.4353714],
    [-34.22628784,19.43449211],
    [-34.22687149,19.43320465],
    [-34.22735214,19.42917061],
    [-34.22750473,19.42819595],
    [-34.22763443,19.42564011],
    [-34.22218704,19.40853119],
    [-34.21998215,19.40515327],
    [-34.21538162,19.40071106],
    [-34.21411514,19.40024185],
    [-34.21243668,19.3996067],
    [-34.21133804,19.39860916],
    [-34.20144653,19.40018463],
    [-34.19548416,19.40439606],
    [-34.18997192,19.40410995],
    [-34.18721771,19.40228271],
    [-34.18579483,19.40216827],
    [-34.17977905,19.40606689],
    [-34.17566681,19.41081619],
    [-34.17302704,19.41193962],
    [-34.17072296,19.41506195],
    [-34.16702271,19.4162693],
    [-34.16611862,19.41756439],
    [-34.16518784,19.42289352],
    [-34.16248322,19.43789101],
    [-34.15910339,19.44477081],
    [-34.1587944,19.44779778],
    [-34.1575737,19.45118141],
    [-34.15696716,19.45508194],
    [-34.15521622,19.45875931],
    [-34.15472794,19.46159363],
    [-34.1554451,19.46792984],
    [-34.15465546,19.47038651],
    [-34.15147018,19.47505188],
    [-34.14575958,19.49320412],
    [-34.14369202,19.49814224],
    [-34.13909149,19.50504303],
    [-34.13911819,19.50516701],
    [-34.13978577,19.50398827],
    [-34.1449585,19.49556732],
    [-34.15222168,19.47358513],
    [-34.15364456,19.4718399],
    [-34.15501022,19.4696331],
    [-34.15552902,19.4669857],
    [-34.1548233,19.46039581],
    [-34.15572357,19.45762825],
    [-34.15715408,19.45449638],
    [-34.15790939,19.44994354],
    [-34.15890884,19.44733429],
    [-34.15914917,19.44460869],
    [-34.16277313,19.43714333],
    [-34.16627884,19.41722488],
    [-34.16750336,19.41594315],
    [-34.17048264,19.41522598],
    [-34.17172241,19.41390038],
    [-34.17265701,19.41229248],
    [-34.17637253,19.41018105],
    [-34.17798996,19.40822792],
    [-34.18080139,19.40518188],
    [-34.18580627,19.40216827],
    [-34.18739319,19.40237427],
    [-34.18952179,19.40398026],
    [-34.19495392,19.40454865],
    [-34.20615005,19.39916801],
    [-34.21130753,19.39866829],
    [-34.21247864,19.3997097],
    [-34.21619797,19.40135384],
    [-34.22165298,19.40754128],
    [-34.22497559,19.41518021],
    [-34.22753906,19.42516136],
    [-34.22695541,19.43251801],
    [-34.22648239,19.43406487],
    [-34.22558975,19.43509293],
    [-34.22502518,19.43544006],
    [-34.22522736,19.43621063],
    [-34.22512423109622,19.43642292464574],
    [-34.22458185115492,19.43629123057586],
    [-34.22431206356399,19.43641970642555],
    [-34.22431307830665,19.43664998025357],
    [-34.22447962496459,19.43695953240818],
    [-34.22454708785079,19.43700502015511],
    [-34.22483519292562,19.43737640552487],
    [-34.22502907765163,19.43715914701668],
    [-34.22528839,19.43676376],
    [-34.22549057,19.43660545],
    [-34.22503281,19.43568802],
    [-34.22507477,19.43550873],
    [-34.22519684,19.43542862],
    [-34.22618866,19.43465996],
    [-34.22689438,19.43316078],
    [-34.22764206,19.42570686],
    [-34.2237587,19.41222382],
    [-34.22249603,19.40925598],
    [-34.22083664,19.40621376],
    [-34.2155838,19.40083313],
    [-34.21223831,19.39944649],
    [-34.21032333,19.39699173],
    [-34.20895767,19.3936615],
    [-34.20866013,19.38978958],
    [-34.21047592,19.3778553],
    [-34.21187592,19.3334713],
    [-34.21235275,19.32855034],
    [-34.2115097,19.32466507],
    [-34.21212006,19.32252884],
    [-34.21699524,19.31750679],
    [-34.22068024,19.31053734],
    [-34.22504044,19.29492569],
    [-34.22509766,19.29225159],
    [-34.22238159,19.28302765],
    [-34.22277451,19.28071594],
    [-34.22592163,19.27377129],
    [-34.22858429,19.27170372],
    [-34.22927475,19.27007294],
    [-34.23022079,19.2650032],
    [-34.2341156,19.2590847],
    [-34.23514175,19.25659752],
    [-34.23519516,19.25372696],
    [-34.23181534,19.23983574],
    [-34.23231125,19.23751068],
    [-34.23378372,19.23453712],
    [-34.23413467,19.23182678],
    [-34.2355957,19.21544266],
    [-34.23476028,19.19260597],
    [-34.23389053,19.19037628],
    [-34.23192596,19.18842316],
    [-34.22997665,19.18777847],
    [-34.22165298,19.18801308],
    [-34.22105408,19.18829346],
    [-34.21938705,19.18959427],
    [-34.21886444,19.18964195],
    [-34.21812057,19.18906593],
    [-34.21801376,19.18778419],
    [-34.21993637,19.18509674],
    [-34.22167969,19.18306541],
    [-34.222332,19.18147278],
    [-34.22206879,19.17821121],
    [-34.2225647,19.17541122],
    [-34.22389221,19.17380524],
    [-34.22401428,19.17341042],
    [-34.22360611,19.17216873],
    [-34.22215652,19.17220497],
    [-34.2190094,19.17354393],
    [-34.21758652,19.17306328],
    [-34.21671295,19.17162323],
    [-34.21622849,19.17022324],
    [-34.2143898,19.16837692],
    [-34.21376419,19.16664314],
    [-34.21372986,19.16361618],
    [-34.21274185,19.16217804],
    [-34.21138,19.16095352],
    [-34.20965195,19.15721321],
    [-34.20796585,19.15535736],
    [-34.20737839,19.15350151],
    [-34.20698166,19.15016747],
    [-34.20584488,19.14781189],
    [-34.20154953,19.14318466],
    [-34.19984818,19.14072227],
    [-34.19923401,19.13830566],
    [-34.19867325,19.13188744],
    [-34.19775391,19.12994003],
    [-34.19113922,19.12377167],
    [-34.1904335,19.12180138],
    [-34.19185257,19.11203766],
    [-34.19216156,19.10917854],
    [-34.19377899,19.10196877],
    [-34.1933136,19.09978485],
    [-34.19204712,19.09812546],
    [-34.18844223,19.09556961],
    [-34.18702698,19.09330368],
    [-34.18166351,19.08236122],
    [-34.17882919,19.07699394],
    [-34.17506409,19.05562973],
    [-34.17219543,19.05004883],
    [-34.1627388,19.03296089],
    [-34.16259384,19.03059769],
    [-34.16326523,19.02824783],
    [-34.16471863,19.02562714],
    [-34.16495514,19.02342224],
    [-34.16479111,19.02249908],
    [-34.16251755,19.01550484],
    [-34.16257477,19.01062965],
    [-34.16372681,19.00669479],
    [-34.16672134,19.000597],
    [-34.16676331,18.9987545],
    [-34.16493988,18.99299812],
    [-34.15913773,18.97603226],
    [-34.15284348,18.96576881],
    [-34.15211105,18.96092415],
    [-34.15155029,18.9528656],
    [-34.1530304,18.94034195],
    [-34.15240479,18.93839645],
    [-34.15006256,18.9338665],
    [-34.14998245,18.93328476],
    [-34.15030289,18.9319191],
    [-34.15107346,18.93050003],
    [-34.15081024,18.92915154],
    [-34.14935303,18.92837143],
    [-34.14599228,18.92924118],
    [-34.14414978,18.92996025],
    [-34.14300156,18.92994118],
    [-34.14077377,18.93071938],
    [-34.13949585,18.93101311],
    [-34.13713074,18.93393898],
    [-34.13630295,18.93637276],
    [-34.13567734,18.93697548],
    [-34.13292313,18.93735313],
    [-34.12751389,18.93349838],
    [-34.12707138,18.93236923],
    [-34.12737274,18.93108749],
    [-34.12825775,18.93039703],
    [-34.12933731,18.93055344],
    [-34.13073349,18.93124199],
    [-34.13189316,18.93115044],
    [-34.13287735,18.93036079],
    [-34.13418198,18.92657471],
    [-34.13502884,18.92331696],
    [-34.13494492,18.92179489],
    [-34.12766266,18.89993286],
    [-34.11827087,18.87765503],
    [-34.11365891,18.86993599],
    [-34.10609436,18.8575573],
    [-34.09858704,18.84922791],
    [-34.09711838,18.84766769],
    [-34.09711838,18.84766769],
    [-34.09525299,18.84567261],
    [-34.08517838,18.83488655],
    [-34.0851059,18.83472824],
    [-34.08354187,18.82858467],
    [-34.0683136,18.81010628],
    [-34.05886459,18.79352188],
    [-34.05847168,18.79012489],
    [-34.05941391,18.77824402],
    [-34.05809784,18.77308846],
    [-34.04922362370969,18.75703164377991],
    [-33.90143500555664,18.42122029495178],
    [-33.90149742517021,18.42147127370751],
    [-33.9012146,18.42209625],
    [-33.90113449,18.42264557],
    [-33.90136719,18.42324257],
    [-33.90179825,18.42373657],
    [-33.90214539,18.42342567],
    [-33.90252026362403,18.42307903116872],
    [-33.90272865359881,18.42304426470219],
]
