import './Header.scss';
import logo from '../../assets/images/Logo_NTTdata.svg';
import { useEffect, useState } from 'react';

interface HeaderProps {
  raceDayNumber: number | null;
}

function Header(props: HeaderProps) {
  const [raceItineraryString, setRaceIntineraryString] = useState<string | null> (null);

  useEffect(() => {
    switch(props.raceDayNumber) { // TODO: day x out of 8
      case 1:
        setRaceIntineraryString("Day 1 | Johannesburg → Kroonstad");
        return;
      case 2:
        setRaceIntineraryString("Day 2 | Kroonstad → Bloemfontein");
        return;
      case 3:
        setRaceIntineraryString("Day 3 | Bloemfontein → Gariepdam");
        return;
      case 4:
        setRaceIntineraryString("Day 4 | Gariepdam → Graaff-Reinet");
        return;
      case 5:
        setRaceIntineraryString("Day 5 | Graaff-Reinet → Graaff-Reinet (blind stage)");
        return;
      case 6:
        setRaceIntineraryString("Day 6 | Graaff-Reinet → Jeffrey's Bay");
        return;
      case 7:
        setRaceIntineraryString("Day 7 | Jeffrey's Bay → Riversdale");
        return;
      case 8:
        setRaceIntineraryString("Day 8 | Riversdale → Cape Town");
        return;
      default: 
        setRaceIntineraryString(null);
        return;
    }
  }, [props.raceDayNumber]);

  return (
    <div className="c-header">
      <div className="c-header__left-block">
        <div className="c-header__backlink">
          <a href="http://www.solarteam.be">
              ← <span className="-underline-on-hover">Back to Solarteam.be</span>
          </a>
        </div>
        <h1 className="c-header__page-title">
            Sasol Solar Challenge
        </h1>
        <h2 className="c-header__date-location">
            {raceItineraryString ? raceItineraryString : "9 - 16 September 2022"}
        </h2>
      </div>
      <div className="c-header__right-block">
          <a href="https://www.nttdata.com/global/en/" className="c-header__logo">
            <div>Powered by</div>
            <img src={logo} alt="Logo NTT Data"/>
          </a>
      </div>
    </div>
  );
}

export default Header;
