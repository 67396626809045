export const day6: [number, number][] = [
    [-32.25003377971741,24.53516337707393],
    [-32.25052643,24.53538895],
    [-32.25716019,24.53796005],
    [-32.25814438,24.53835678],
    [-32.26468277,24.54156494],
    [-32.26879501,24.54474068],
    [-32.27105713,24.54533768],
    [-32.27151871,24.54619217],
    [-32.27188873,24.54727745],
    [-32.27481461,24.54972649],
    [-32.28651047,24.55876923],
    [-32.28865814,24.55908394],
    [-32.3001709,24.55651283],
    [-32.30883789,24.55481339],
    [-32.31772614,24.55905342000001],
    [-32.33220291,24.56837654],
    [-32.33568954,24.57198906],
    [-32.33819962,24.57735062],
    [-32.36071777,24.59485816999999],
    [-32.39486694,24.61149216],
    [-32.41976166,24.62185669],
    [-32.42990875,24.62613297],
    [-32.46493149,24.64071465],
    [-32.50071716,24.6524334],
    [-32.53664017,24.66345215],
    [-32.57292938,24.67258453],
    [-32.60745239,24.68123436],
    [-32.63406754,24.68942451],
    [-32.63666153,24.69145393000001],
    [-32.64245987,24.69174957],
    [-32.64307022,24.69165421],
    [-32.68005753,24.69246483],
    [-32.71579742,24.70379448],
    [-32.75123978,24.71649361],
    [-32.7742424,24.7261734],
    [-32.77701569,24.72563171],
    [-32.79707336,24.71829224],
    [-32.79919434,24.71877098],
    [-32.80091476,24.72023773],
    [-32.80284882,24.72245026],
    [-32.80479813,24.72328186],
    [-32.80578613,24.7233181],
    [-32.83182907,24.71253014],
    [-32.84436035,24.71408272],
    [-32.84614563,24.71505927999999],
    [-32.84730911,24.71681404],
    [-32.84910583,24.72104073],
    [-32.84942245,24.72284698],
    [-32.85044479,24.72476006],
    [-32.85323334,24.73197937],
    [-32.85331726,24.73209572],
    [-32.85474777,24.73267555],
    [-32.85605621,24.73148918],
    [-32.85967255,24.72513770999999],
    [-32.86587143,24.71981239],
    [-32.87731934,24.71028519],
    [-32.88171768,24.70845032],
    [-32.88404465,24.70807457],
    [-32.88565445,24.70685577],
    [-32.88778687,24.70307731999999],
    [-32.89201736,24.69957161],
    [-32.89994431,24.68815994],
    [-32.90260315,24.68532753],
    [-32.92599869,24.67413902],
    [-32.92805481,24.67242432],
    [-32.92936707,24.67202187],
    [-32.93951035,24.66960144],
    [-32.94260025,24.667799],
    [-32.9499855,24.66687775],
    [-32.95193481,24.66691589],
    [-32.95338058,24.66782951],
    [-32.97187805,24.69478416],
    [-32.99253845,24.73131752],
    [-33.01094818,24.76951981],
    [-33.02811813,24.80850792],
    [-33.04020309,24.83461189],
    [-33.04928589,24.84388924],
    [-33.07052612,24.86483765],
    [-33.07994843,24.86661911],
    [-33.09775543,24.86838722],
    [-33.1165123,24.86375046],
    [-33.15283966,24.85482788],
    [-33.18901443,24.84688377000001],
    [-33.19641876,24.84692001],
    [-33.22403717,24.83477783],
    [-33.23063278,24.83222008],
    [-33.23255157,24.83269691],
    [-33.23544693,24.83552742],
    [-33.24272156,24.83785248],
    [-33.2451973,24.83724403],
    [-33.247509,24.83711243],
    [-33.26693726,24.84010315],
    [-33.27244186,24.83827782],
    [-33.29240799,24.83083344],
    [-33.29880142,24.83218002],
    [-33.30112839,24.83378792],
    [-33.30547714,24.84024811],
    [-33.32793427,24.87476921],
    [-33.33997345,24.8993206],
    [-33.34140396,24.90697479],
    [-33.34373856,24.91874695],
    [-33.34528351,24.92244339],
    [-33.34575272,24.92752266],
    [-33.34567642,24.93078232],
    [-33.34818268,24.939785],
    [-33.34981537,24.94308853],
    [-33.36391068,24.95835685999999],
    [-33.36452484,24.96146965],
    [-33.36562729,24.97407532],
    [-33.36520386,24.97771072],
    [-33.36335754,24.98574448],
    [-33.36679459,25.01761055],
    [-33.36828613,25.023489],
    [-33.37251282,25.02913284],
    [-33.37275696,25.03167915],
    [-33.3719635,25.03404808],
    [-33.36861038,25.03820801],
    [-33.36772537,25.04154587],
    [-33.37015915,25.0571003],
    [-33.37419128,25.09315109],
    [-33.37895203,25.12142372],
    [-33.38580704,25.13434982],
    [-33.40413284,25.16827774],
    [-33.41677856,25.19294167],
    [-33.41856766,25.20940208],
    [-33.4226265,25.24374199],
    [-33.4260788,25.25240517],
    [-33.43893814,25.27292633],
    [-33.43447495,25.28396225],
    [-33.41854858,25.31607437],
    [-33.4046669,25.34954834],
    [-33.40290833,25.35449028],
    [-33.40282822,25.35783768],
    [-33.40478516,25.36949157999999],
    [-33.40371704,25.3761673],
    [-33.39861298,25.40599632],
    [-33.39701462,25.41606330999999],
    [-33.39920044,25.43373871],
    [-33.39989853,25.43943024],
    [-33.39780426,25.43982315],
    [-33.39764023,25.43889427],
    [-33.39765046193112,25.43885970913584],
    [-33.39776832355516,25.43983664498004],
    [-33.39976883,25.4394989],
    [-33.39853287,25.42835617],
    [-33.39703369,25.41527748],
    [-33.39963913,25.39993477],
    [-33.40459061,25.37094307],
    [-33.40470886,25.36714745],
    [-33.40278244,25.35533524],
    [-33.4037323,25.35171509],
    [-33.40785599,25.34201622],
    [-33.42035675,25.31158638],
    [-33.43507385,25.2827034],
    [-33.43890381,25.27305222],
    [-33.43918228,25.27317619],
    [-33.44924927,25.28764153],
    [-33.47280121,25.32152748],
    [-33.49463654,25.34802818],
    [-33.51589966,25.37523270000001],
    [-33.52280807,25.38955498],
    [-33.52220154,25.39011192],
    [-33.51428604,25.3917923],
    [-33.51189041,25.39317703],
    [-33.48382568,25.4096489],
    [-33.45570374,25.42618179],
    [-33.43260956,25.44069672],
    [-33.43071365,25.44301033],
    [-33.42969131,25.44422913],
    [-33.42883301,25.44318199],
    [-33.42762375,25.44235611],
    [-33.42609787,25.44215775],
    [-33.41149521,25.44477081],
    [-33.40089798,25.44665909],
    [-33.40078354,25.44640923],
    [-33.40050888,25.44423294],
    [-33.39992142,25.43954659],
    [-33.39781189,25.43984222],
    [-33.39765549,25.43894386],
    [-33.39764279471204,25.43885609949776],
    [-33.39777241933827,25.43983628167882],
    [-33.39983368,25.43948174],
    [-33.40034103,25.44289207],
    [-33.40080643,25.44659996],
    [-33.40868378,25.44527245],
    [-33.42627716,25.44212151],
    [-33.42765427,25.44235992],
    [-33.42884445,25.44320107],
    [-33.42959213,25.44424248],
    [-33.43825531,25.4367485],
    [-33.44631195,25.43217278],
    [-33.47328186,25.41581535],
    [-33.50188446,25.39904022],
    [-33.51607132,25.39113617],
    [-33.52246475,25.39001274],
    [-33.52285004,25.38971138],
    [-33.52526855,25.39215279],
    [-33.53005219,25.39630318],
    [-33.53433228,25.40563202],
    [-33.5455246,25.41933632],
    [-33.54774857,25.42194176],
    [-33.55297852,25.42482948],
    [-33.55530167,25.4249649],
    [-33.55839539,25.42448616],
    [-33.56989288,25.4292202],
    [-33.57149124,25.42928886],
    [-33.57369232,25.43071365],
    [-33.57602692,25.43188095],
    [-33.5821228,25.43274879],
    [-33.58386612,25.43190956],
    [-33.58834457,25.4281044],
    [-33.58995438,25.42804718],
    [-33.5995636,25.43637466],
    [-33.60001373,25.43651199],
    [-33.63642502,25.44536018000001],
    [-33.67159653,25.45912361],
    [-33.68471909,25.46212006],
    [-33.68699265,25.46154213],
    [-33.7001152,25.45664406],
    [-33.70478058,25.45502663],
    [-33.72922897,25.43065834],
    [-33.73152161,25.42919159],
    [-33.73606491,25.42891502],
    [-33.75016785,25.42973518],
    [-33.76401138,25.43088722],
    [-33.77004623,25.43387413],
    [-33.77151871,25.43400955],
    [-33.7944564819336,25.4307918548584],
    [-33.7977485656738,25.4317493438721],
    [-33.8046989440918,25.4348545074463],
    [-33.8063583374023,25.4358520507813],
    [-33.8076705932617,25.43740272521971],
    [-33.8085899353027,25.4397315979004],
    [-33.8087120056152,25.4426727294922],
    [-33.8085021972656,25.4478302001953],
    [-33.8094100952148,25.4509868621826],
    [-33.8106079101563,25.4544124603271],
    [-33.8108291625977,25.4569911956787],
    [-33.810417175293,25.4595317840576],
    [-33.8083038330078,25.465726852417],
    [-33.8081703186035,25.4683208465576],
    [-33.8084564208984,25.4746227264404],
    [-33.8082008361816,25.4760704040527],
    [-33.8065338134766,25.4824142456055],
    [-33.8067932128906,25.4857368469238],
    [-33.807674407959,25.4884967803955],
    [-33.8098068237305,25.4929027557373],
    [-33.8102493286133,25.4954700469971],
    [-33.8112449645996,25.497091293335],
    [-33.8142471313477,25.49861907958981],
    [-33.8162498474121,25.5002212524414],
    [-33.8233489990234,25.5070648193359],
    [-33.8427658081055,25.5262699127197],
    [-33.861930847168,25.5453186035156],
    [-33.8720092773438,25.5550231933594],
    [-33.8836898803711,25.5605697631836],
    [-33.8960342407227,25.5671787261963],
    [-33.9047966003418,25.5744514465332],
    [-33.9120750427246,25.5818634033203],
    [-33.9128494262695,25.5827083587646],
    [-33.91367056910399,25.58358755426252],
    [-33.91386423292467,25.58112000178099],
    [-33.91410222973074,25.57982397058928],
    [-33.9146270751953,25.5781879425049],
    [-33.9159965515137,25.5762805938721],
    [-33.9229507446289,25.5674800872803],
    [-33.9233779907227,25.5661220550537],
    [-33.9238586425781,25.5641899108887],
    [-33.9248313903809,25.5627059936523],
    [-33.9280815124512,25.5594501495361],
    [-33.9283409118652,25.5592555999756],
    [-33.931812286377,25.5577907562256],
    [-33.9339256286621,25.5574417114258],
    [-33.9353103637695,25.5566806793213],
    [-33.9366798400879,25.5551929473877],
    [-33.941535949707,25.54800796508791],
    [-33.9461517333984,25.5330848693848],
    [-33.9479026794434,25.5180511474609],
    [-33.9484939575195,25.5106811523438],
    [-33.9450607299805,25.4873828887939],
    [-33.9462928771973,25.4741268157959],
    [-33.9495964050293,25.4382495880127],
    [-33.9501686096191,25.4319934844971],
    [-33.9510231018066,25.4194374084473],
    [-33.9502029418945,25.4147205352783],
    [-33.9428100585938,25.3890991210938],
    [-33.9314918518066,25.346700668335],
    [-33.9215965270996,25.3037700653076],
    [-33.9116744995117,25.2608528137207],
    [-33.9088439941406,25.2472019195557],
    [-33.90993118,25.22887611000001],
    [-33.90988922,25.2218647],
    [-33.91059113,25.20082283],
    [-33.90991592,25.19875526],
    [-33.89665604,25.15742683],
    [-33.89191818,25.13916397],
    [-33.89238739,25.13448524],
    [-33.89559555,25.12493515],
    [-33.90317917,25.10845947],
    [-33.90309906,25.10125351],
    [-33.90145111,25.09616279999999],
    [-33.901577,25.08720015999999],
    [-33.90345001,25.08127022],
    [-33.91054535,25.06925201],
    [-33.91272736,25.06793213],
    [-33.91695404,25.06729317],
    [-33.91923141,25.06539917],
    [-33.92200089,25.06163597],
    [-33.92353058,25.0559063],
    [-33.93445587,25.02173042],
    [-33.93481064,25.00444221],
    [-33.94039154,24.99292755],
    [-33.95218658,24.96952438],
    [-33.9549675,24.96403313],
    [-33.96212006,24.95140648],
    [-33.98178101,24.93421936],
    [-34.00210571,24.90573692],
    [-34.00537491,24.90015411],
    [-34.01119995,24.88819313],
    [-34.01278687,24.88622475],
    [-34.0143013,24.88530922],
    [-34.0174408,24.88884354],
    [-34.01883275566622,24.89054446687861],
]
