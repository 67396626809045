export const day7: [number, number][] = [
    [-34.01885131239451,24.89060131355014],
    [-34.01754771665781, 24.89119488194316],
    [-34.01769258515711,24.89103539966899],
    [-34.01698513769313,24.8916647888311],
    [-34.01885117886088,24.89060206196713],
    [-34.0144301,24.88492400000001],
    [-34.0147794,24.883239],
    [-34.0151619,24.8819414],
    [-34.0163355,24.8797935],
    [-34.0193713,24.8746712],
    [-34.0209656,24.8720452],
    [-34.023206,24.8683762],
    [-34.0251101,24.8652172],
    [-34.0267751,24.8623581],
    [-34.0275764,24.860262],
    [-34.0277837,24.8594313],
    [-34.027993,24.8581715],
    [-34.0280956,24.8562575],
    [-34.0279346,24.8543472],
    [-34.0273843,24.8520518],
    [-34.0264472,24.848747],
    [-34.0253977,24.8450516],
    [-34.0232966,24.8376617],
    [-34.022127,24.8335572],
    [-34.0165385,24.8139156],
    [-34.0127666,24.8006929],
    [-34.0104259,24.7924857],
    [-34.0087267,24.7864167],
    [-34.0083139,24.7849595],
    [-34.0071957,24.7811015],
    [-34.0053202,24.7744514],
    [-34.0036155,24.7685809],
    [-34.0018408,24.7624078],
    [-34.0004526,24.7575916],
    [-33.9988002,24.7517616],
    [-33.9979855,24.7488877],
    [-33.9961855,24.7425224],
    [-33.9941834,24.7353249],
    [-33.9939534,24.7331788],
    [-33.994077,24.7308085],
    [-33.9948614,24.7214053],
    [-33.9954694,24.7138481],
    [-33.9956986,24.7101403],
    [-33.9955041,24.7078021],
    [-33.9947144,24.7043108],
    [-33.9941589,24.7020399],
    [-33.993783,24.6996466],
    [-33.9938552,24.6965214],
    [-33.9944935,24.6937495],
    [-33.9961608,24.6902518],
    [-33.9972813,24.6885036],
    [-33.9982425,24.6870159],
    [-34.0004554,24.6836188],
    [-34.0012951,24.6823067],
    [-34.0035515,24.6788037],
    [-34.0049517,24.6761693],
    [-34.0060266,24.6729518],
    [-34.007509,24.6682338],
    [-34.0087105,24.6644012],
    [-34.0094943,24.6616001],
    [-34.0096896,24.66046],
    [-34.0099816,24.6543684],
    [-34.0101323,24.6521779],
    [-34.0104667,24.6506418],
    [-34.0115917,24.6471676],
    [-34.0134781,24.6412277],
    [-34.0137772,24.6396835],
    [-34.0141225,24.6364555],
    [-34.0144311,24.6339462],
    [-34.0153406,24.6299876],
    [-34.0166041,24.6255992],
    [-34.0182709,24.6220023],
    [-34.0191069,24.62033],
    [-34.0204072,24.6177285],
    [-34.0225889,24.6133541],
    [-34.0263419,24.6057647],
    [-34.0277125,24.602984],
    [-34.0294309,24.59952609999999],
    [-34.0312391,24.5962823],
    [-34.0327497,24.5946274],
    [-34.0368209,24.591414],
    [-34.0390424,24.58963019999999],
    [-34.0409138,24.5867602],
    [-34.0414806,24.584553],
    [-34.0415464,24.5826472],
    [-34.0413082,24.58111299999999],
    [-34.0408473,24.5796518],
    [-34.0396823,24.5775174],
    [-34.0388743,24.5761359],
    [-34.0381473,24.5748954],
    [-34.0364811,24.571791],
    [-34.0358934,24.5704644],
    [-34.0346537,24.5671454],
    [-34.0340274,24.56534419999999],
    [-34.0329902,24.5623387],
    [-34.0320622,24.5595252],
    [-34.0318813,24.5584399],
    [-34.0318996,24.5567229],
    [-34.0322147,24.5552557],
    [-34.032725,24.5540492],
    [-34.0341162,24.5522963],
    [-34.0358915,24.5510551],
    [-34.0376319,24.5498691],
    [-34.0385083,24.5492546],
    [-34.039796,24.5481874],
    [-34.0410159,24.5466997],
    [-34.041479,24.5459538],
    [-34.0419773,24.5448793],
    [-34.043312,24.5415523],
    [-34.0443172,24.5390045],
    [-34.0450284,24.53721],
    [-34.0465376,24.5333088],
    [-34.0477766,24.530174],
    [-34.049063,24.5269589],
    [-34.0516597,24.520344],
    [-34.0526904,24.5177471],
    [-34.0536238,24.5153677],
    [-34.0548752,24.5107014],
    [-34.0550106,24.5094682],
    [-34.0550798,24.5080095],
    [-34.0549768,24.5056019],
    [-34.0548648,24.504188],
    [-34.0545303,24.5005374],
    [-34.0536007,24.4901661],
    [-34.0523324,24.4760325],
    [-34.0517226,24.4693432],
    [-34.0508934,24.4602993],
    [-34.0501952,24.4527314],
    [-34.0497789,24.4478101],
    [-34.0494678,24.4441906],
    [-34.0484999,24.4333996],
    [-34.0477456,24.4248652],
    [-34.0472196,24.4191562],
    [-34.0467572,24.4145487],
    [-34.0464871,24.4131498],
    [-34.0448924,24.4078456],
    [-34.0432495,24.4026051],
    [-34.0405872,24.3941152],
    [-34.0396337,24.3910958],
    [-34.0384947,24.3874638],
    [-34.0375796,24.3844915],
    [-34.0360937,24.3798231],
    [-34.0346454,24.3751609],
    [-34.0320196,24.3668454],
    [-34.0307566,24.3628467],
    [-34.0297363,24.3595342],
    [-34.0279499,24.3538525],
    [-34.0268364,24.3502922],
    [-34.0253343,24.345533],
    [-34.0243385,24.3423626],
    [-34.0225714,24.3368405],
    [-34.0217004,24.3341007],
    [-34.0195713,24.327304],
    [-34.0181418,24.3227422],
    [-34.0161715,24.3164384],
    [-34.0150893,24.3129676],
    [-34.0125462,24.304885],
    [-34.0103612,24.2978893],
    [-34.0095535,24.2952236],
    [-34.0083025,24.2895876],
    [-34.0075825,24.2856618],
    [-34.0071184,24.2837238],
    [-34.0062696,24.2815682],
    [-34.0045384,24.2771771],
    [-34.0039348,24.2741152],
    [-34.0028563,24.2657545],
    [-34.0022051,24.2607411],
    [-34.0012505,24.2535405],
    [-34.0005609,24.2481813],
    [-33.9996994,24.2418166],
    [-33.9989058,24.2356785],
    [-33.9980084,24.2289859],
    [-33.9975818,24.2256987],
    [-33.9969891,24.2211993],
    [-33.9962441,24.2156034],
    [-33.9957754,24.2119862],
    [-33.9953969,24.2089835],
    [-33.9951635,24.2066142],
    [-33.9947563,24.2003422],
    [-33.99456,24.1973142],
    [-33.9944059,24.1949351],
    [-33.9941957,24.1916909],
    [-33.9940414,24.1893118],
    [-33.9934379,24.1800119],
    [-33.9931431,24.1754701],
    [-33.9929761,24.1730923],
    [-33.9925558,24.1668217],
    [-33.9922472,24.1618462],
    [-33.9921285,24.1601166],
    [-33.9918155,24.1551415],
    [-33.991724,24.1529751],
    [-33.9916598,24.1493381],
    [-33.9915721,24.1440584],
    [-33.9915166,24.1403711],
    [-33.9914809,24.1378342],
    [-33.99144,24.1338458],
    [-33.9913658,24.1302526],
    [-33.9912547,24.1222998],
    [-33.9911508,24.1165591],
    [-33.9910595,24.1096181],
    [-33.9909606,24.1034108],
    [-33.9908614,24.0959536],
    [-33.9907437,24.0903165],
    [-33.9900353,24.0856337],
    [-33.9891612,24.082327],
    [-33.988525,24.0800361],
    [-33.9869077,24.0741316],
    [-33.9859846,24.0705715],
    [-33.983174,24.0602946],
    [-33.9814029,24.0538933],
    [-33.9805113,24.0504429],
    [-33.9803383,24.0488684],
    [-33.9802711,24.0459557],
    [-33.9808552,24.0422073],
    [-33.9820051,24.0379738],
    [-33.9824003,24.0349824],
    [-33.9823454,24.0225491],
    [-33.98235,24.0136252],
    [-33.9819776,24.0085723],
    [-33.9816651,24.0053484],
    [-33.9810971,23.9979012],
    [-33.9805316,23.9917056],
    [-33.979972,23.9847973],
    [-33.9792204,23.9761776],
    [-33.978869,23.9741564],
    [-33.9771464,23.967981],
    [-33.9758644,23.9639843],
    [-33.9705198,23.9486967],
    [-33.9698162,23.9452677],
    [-33.9696315,23.9426398],
    [-33.9690639,23.9365087],
    [-33.9685672,23.9324184],
    [-33.9683448,23.9306584],
    [-33.9681845,23.9288395],
    [-33.9680994,23.9268514],
    [-33.9679656,23.9245272],
    [-33.9678237,23.9217255],
    [-33.9676247,23.9177061],
    [-33.9675307,23.9154465],
    [-33.9674141,23.9121965],
    [-33.9672302,23.9076465],
    [-33.9669611,23.9013667],
    [-33.9668022,23.8974679],
    [-33.9667384,23.89509380000001],
    [-33.9664675,23.8899861],
    [-33.9663629,23.8868549],
    [-33.9661606,23.8834818],
    [-33.9657989,23.8775108],
    [-33.9653045,23.8729672],
    [-33.9649161,23.86935039999999],
    [-33.9652204,23.8666538],
    [-33.9658213,23.865196],
    [-33.9670331,23.8628249],
    [-33.968357,23.8602202],
    [-33.9692129,23.8574387],
    [-33.9698362,23.8517861],
    [-33.9709955,23.8413405],
    [-33.9716342,23.8348294],
    [-33.9730271,23.8222848],
    [-33.9736907,23.8158195],
    [-33.9744147,23.808364],
    [-33.9754019,23.7974562],
    [-33.976408,23.7876342],
    [-33.9766808,23.7856042],
    [-33.9771492,23.7807192],
    [-33.9772273,23.7767991],
    [-33.9768106,23.77263],
    [-33.9761682,23.7695547],
    [-33.9754324,23.7659848],
    [-33.9749123,23.7625022],
    [-33.9745938,23.7575927],
    [-33.974487,23.7532569],
    [-33.9743484,23.7487355],
    [-33.9742966,23.7470015],
    [-33.9741737,23.7435345],
    [-33.9739168,23.7334472],
    [-33.9737835,23.7285449],
    [-33.9734737,23.7220498],
    [-33.9730195,23.716745],
    [-33.9722336,23.7081112],
    [-33.9716312,23.7016557],
    [-33.9709815,23.6945416],
    [-33.9698743,23.68247],
    [-33.9693004,23.6762187],
    [-33.9685445,23.6681306],
    [-33.9674739,23.6567099],
    [-33.9672651,23.6544506],
    [-33.9671099,23.6526896],
    [-33.966926,23.6500209],
    [-33.9668887,23.6489375],
    [-33.9668669,23.6462416],
    [-33.9668567,23.6452485],
    [-33.9668435,23.6440123],
    [-33.9668309,23.641949],
    [-33.9668051,23.6408655],
    [-33.9667002,23.6397258],
    [-33.9663358,23.6380575],
    [-33.9658417,23.6367533],
    [-33.9644458,23.6344636],
    [-33.9633357,23.6333785],
    [-33.9595717,23.6304126],
    [-33.9565575,23.6280456],
    [-33.9542881,23.6262865],
    [-33.951878,23.6243911],
    [-33.9493456,23.6223555],
    [-33.9467961,23.6204227],
    [-33.9448071,23.6188819],
    [-33.9411497,23.6159547],
    [-33.9397884,23.6144243],
    [-33.9389466,23.6130051],
    [-33.9381686,23.6111669],
    [-33.9376647,23.6089487],
    [-33.9375952,23.6070121],
    [-33.9377093,23.602323],
    [-33.9377597,23.6005628],
    [-33.9378528,23.5971393],
    [-33.9379022,23.5951165],
    [-33.9379639,23.5929498],
    [-33.9380212,23.5903493],
    [-33.9380682,23.5885917],
    [-33.9381993,23.5842916],
    [-33.9383462,23.5791765],
    [-33.938485,23.5742168],
    [-33.9385232,23.572366],
    [-33.9385639,23.56889779999999],
    [-33.9386753,23.5647808],
    [-33.9387392,23.5621584],
    [-33.9388401,23.5582799],
    [-33.9388981,23.5558961],
    [-33.9389211,23.5536797],
    [-33.9386501,23.5511535],
    [-33.9381187,23.5493129],
    [-33.9367092,23.546594],
    [-33.9342707,23.5425199],
    [-33.9335093,23.5410504],
    [-33.932898,23.5387876],
    [-33.9327715,23.5372443],
    [-33.932926,23.5346863],
    [-33.9331698,23.5314478],
    [-33.9338116,23.5234643],
    [-33.9344493,23.5156979],
    [-33.9346883,23.5124573],
    [-33.9348597,23.5070007],
    [-33.9348381,23.5035833],
    [-33.9346436,23.4999591],
    [-33.9345177,23.4979833],
    [-33.9344397,23.4954973],
    [-33.934878,23.4933771],
    [-33.9356265,23.4919764],
    [-33.9365656,23.4909118],
    [-33.9382874,23.4899554],
    [-33.943286,23.4877694],
    [-33.9453125,23.4863023],
    [-33.9475304,23.4837167],
    [-33.9479289,23.4830605],
    [-33.9490337,23.4813279],
    [-33.949662,23.4803388],
    [-33.9508962,23.4784057],
    [-33.9521181,23.4770095],
    [-33.9533366,23.4760905],
    [-33.9552562,23.4748098],
    [-33.9567731,23.4737276],
    [-33.9601718,23.4714372],
    [-33.9617723,23.4702919],
    [-33.9636452,23.4670126],
    [-33.9652332,23.4638107],
    [-33.965742,23.4624211],
    [-33.9657889,23.4613586],
    [-33.9652883,23.4591091],
    [-33.9652074,23.458284],
    [-33.9656404,23.4560656],
    [-33.9658218,23.4546877],
    [-33.9655428,23.4529957],
    [-33.9653902,23.4516095],
    [-33.9656951,23.450225],
    [-33.9663214,23.4491564],
    [-33.9678397,23.4479007],
    [-33.9684539,23.4473162],
    [-33.9690479,23.44627910000001],
    [-33.9693126,23.4445915],
    [-33.969171,23.4435089],
    [-33.9687127,23.4417378],
    [-33.9689577,23.4395558],
    [-33.9698091,23.43753450000001],
    [-33.9699645,23.43603],
    [-33.9703865,23.4331719],
    [-33.9710324,23.4321082],
    [-33.9719107,23.4313758],
    [-33.9732715,23.4310514],
    [-33.9742885,23.431241],
    [-33.9755653,23.4321409],
    [-33.9787292,23.4346279],
    [-33.9811153,23.4365432],
    [-33.9832688,23.4381393],
    [-33.9840164,23.43848789999999],
    [-33.9863095,23.4384776],
    [-33.9894517,23.4380647],
    [-33.9905272,23.4377954],
    [-33.9917169,23.4367796],
    [-33.9930543,23.4352014],
    [-33.9947481,23.4347413],
    [-33.9964525,23.4349464],
    [-33.9977219,23.43470930000001],
    [-33.9991579,23.4336884],
    [-34.0000126,23.4323611],
    [-34.0010087,23.4287707],
    [-34.0016346,23.426254],
    [-34.002622,23.4222225],
    [-34.0033099,23.4203159],
    [-34.0048398,23.4184938],
    [-34.0064398,23.4165117],
    [-34.007002,23.4155795],
    [-34.0073025,23.4148924],
    [-34.007515,23.4138198],
    [-34.0075019,23.4129305],
    [-34.0070673,23.411161],
    [-34.0062889,23.4084279],
    [-34.0058954,23.4073869],
    [-34.0053415,23.4063692],
    [-34.0034139,23.40431600000001],
    [-34.0025196,23.4025756],
    [-34.0024169,23.4010735],
    [-34.0026425,23.3986086],
    [-34.0029706,23.3974924],
    [-34.0036811,23.3965346],
    [-34.0045364,23.3958458],
    [-34.0057005,23.3949868],
    [-34.0084659,23.3926513],
    [-34.0105466,23.3906456],
    [-34.0123963,23.3889201],
    [-34.0148942,23.3867943],
    [-34.0175105,23.3848881],
    [-34.022428,23.3816624],
    [-34.0250714,23.3799307],
    [-34.0273549,23.3784141],
    [-34.0335104,23.3742782],
    [-34.0363296,23.3724615],
    [-34.0396535,23.3702356],
    [-34.0416384,23.3689285],
    [-34.0430443,23.367912],
    [-34.0439672,23.3664964],
    [-34.0443322,23.3643675],
    [-34.0451034,23.3625008],
    [-34.0458562,23.3617746],
    [-34.0478873,23.3604775],
    [-34.0487525,23.3596711],
    [-34.0495906,23.3583509],
    [-34.0499164,23.3573578],
    [-34.050115,23.3570856],
    [-34.0501697,23.3570664],
    [-34.0502399,23.3569972],
    [-34.050275,23.3568936],
    [-34.0502648,23.356784],
    [-34.0511688,23.3541595],
    [-34.0535315,23.3478308],
    [-34.0541782,23.3461256],
    [-34.0545574,23.3445032],
    [-34.0545306,23.3422442],
    [-34.0541984,23.3409883],
    [-34.0535914,23.3396672],
    [-34.0519063,23.3370208],
    [-34.0496204,23.3332742],
    [-34.0479851,23.33049340000001],
    [-34.0469112,23.3282855],
    [-34.0462735,23.3258404],
    [-34.0452237,23.3203397],
    [-34.0445612,23.3166877],
    [-34.0442835,23.3102158],
    [-34.0442678,23.3070633],
    [-34.0443131,23.298341],
    [-34.0442849,23.2874564],
    [-34.0443391,23.2843258],
    [-34.0442918,23.2795423],
    [-34.0443029,23.2722351],
    [-34.0443367,23.2688925],
    [-34.0443151,23.2669135],
    [-34.0439325,23.2628219],
    [-34.0431659,23.2590333],
    [-34.0423301,23.2556812],
    [-34.039508,23.2443274],
    [-34.0383016,23.23896],
    [-34.0379651,23.2369314],
    [-34.0376039,23.2339947],
    [-34.0373921,23.2314031],
    [-34.0373494,23.226989],
    [-34.0373561,23.2241471],
    [-34.0376097,23.2145152],
    [-34.0375157,23.2125765],
    [-34.0372135,23.2104595],
    [-34.0369436,23.2080954],
    [-34.0368749,23.2065783],
    [-34.0367564,23.2046311],
    [-34.0365728,23.2037922],
    [-34.0361575,23.2025911],
    [-34.0357303,23.2013971],
    [-34.0331271,23.1942675],
    [-34.0324498,23.1916994],
    [-34.0320654,23.1897311],
    [-34.0314986,23.1885335],
    [-34.0299407,23.185807],
    [-34.0295336,23.1845665],
    [-34.0291835,23.1820734],
    [-34.0287773,23.1806307],
    [-34.0275261,23.1782325],
    [-34.0270987,23.1770358],
    [-34.0269614,23.1755844],
    [-34.0273183,23.1729743],
    [-34.0273243,23.1708407],
    [-34.0271079,23.1696368],
    [-34.0258784,23.1663538],
    [-34.0249769,23.1642291],
    [-34.0246651,23.1629831],
    [-34.0248189,23.1617237],
    [-34.0253532,23.1605892],
    [-34.0263183,23.1597536],
    [-34.027957,23.159362],
    [-34.0309887,23.1588814],
    [-34.0337254,23.1584407],
    [-34.0347238,23.1579603],
    [-34.0358139,23.1564943],
    [-34.0360962,23.1554425],
    [-34.0365915,23.1512849],
    [-34.0371086,23.1466423],
    [-34.037607,23.1421241],
    [-34.0377968,23.14040290000001],
    [-34.0379288,23.1392457],
    [-34.0379434,23.1373787],
    [-34.0371617,23.1344084],
    [-34.035448,23.1291186],
    [-34.0349085,23.1272789],
    [-34.0348687,23.126107],
    [-34.035412,23.1243675],
    [-34.0370454,23.1226127],
    [-34.0394527,23.1203377],
    [-34.0409454,23.1186196],
    [-34.0415504,23.11646500000001],
    [-34.0415023,23.1138754],
    [-34.0418226,23.1124667],
    [-34.0427852,23.11008],
    [-34.0430092,23.1084993],
    [-34.0423652,23.1063248],
    [-34.0416882,23.1045001],
    [-34.0416974,23.1029878],
    [-34.0418705,23.10114069999999],
    [-34.04205,23.10001310000001],
    [-34.042435,23.098642],
    [-34.0438802,23.0956077],
    [-34.0448999,23.0935978],
    [-34.045004,23.0914822],
    [-34.0445593,23.0902648],
    [-34.0442223,23.0892663],
    [-34.0441006,23.0880576],
    [-34.0439837,23.0864778],
    [-34.0429182,23.0825688],
    [-34.041403,23.076988],
    [-34.0405026,23.0709735],
    [-34.0402711,23.0694817],
    [-34.0398761,23.0678633],
    [-34.0386213,23.066013],
    [-34.0379464,23.0649099],
    [-34.037848,23.0641671],
    [-34.0380856,23.0633412],
    [-34.0382013,23.0626129],
    [-34.0378758,23.0604884],
    [-34.0374865,23.0588856],
    [-34.037568,23.057181],
    [-34.0377088,23.0569333],
    [-34.0375201,23.0558627],
    [-34.0377035,23.0552653],
    [-34.0390438,23.0541463],
    [-34.0400923,23.0524828],
    [-34.039989,23.0512897],
    [-34.0401475,23.0504811],
    [-34.0406277,23.0488797],
    [-34.0401064,23.0456412],
    [-34.03864514164376,23.04375118079535],
    [-34.03692891618454,23.03880244513589],
    [-34.03702759956064,23.03672223625614],
    [-34.03648084900691,23.0331755510135],
    [-34.03696127560465,23.03032189015561],
    [-34.03817318036243,23.01937748755696],
    [-34.03891040015718,23.01496395424741],
    [-34.03763932661802,23.01235614928004],
    [-34.03364439217766,23.00982010388564],
    [-34.03167239926839,23.00688433012037],
    [-34.02905388456259,23.00501609277558],
    [-34.02891760419272,23.00255695465501],
    [-34.02822785222137,23.00030767649966],
    [-34.02708466782529,22.99831337931706],
    [-34.02703720019652,22.99517666141788],
    [-34.02944583364779,22.99289945706234],
    [-34.03242245173068,22.98985185898993],
    [-34.03202698826146,22.99019539623794],
    [-34.03226803246766,22.99106311019717],
    [-34.03495778376094,22.99345995439027],
    [-34.03701780277741,22.99594320675836],
    [-34.03950266238961,22.99723835298647],
    [-34.0411275337491,22.99700301119573],
    [-34.04317381416499,22.99520119334463],
    [-34.0440631854993,22.99358228943998],
    [-34.04586174237196,22.99036913519296],
    [-34.047297507352,22.98845567627964],
    [-34.04856798044902,22.98817060654873],
    [-34.04965885218865,22.9898196168475],
    [-34.05125534466008,22.98942015085649],
    [-34.05214515321786,22.98895486634713],
    [-34.05310318164143,22.99012359420045],
    [-34.0523914203334,22.98913099885153],
    [-34.05153156296866,22.98909824097792],
    [-34.0501813809127,22.98994645964896],
    [-34.04886730577531,22.98876547730746],
    [-34.047718368031,22.98802838965515],
    [-34.04637281066816,22.98960334155348],
    [-34.04484086090675,22.99220084343065],
    [-34.0433181672028,22.99495913656853],
    [-34.04207089899745,22.99644979436535],
    [-34.04001829005715,22.99727640948652],
    [-34.03802158408112,22.9967738825239],
    [-34.03566950565735,22.99432951596135],
    [-34.03288848268919,22.99166655044696],
    [-34.03203830529853,22.99050131583682],
    [-34.03219921175582,22.98984299632141],
    [-34.03268938869363,22.99059109393115],
    [-34.02745586572453,22.99456725854899],
    [-34.02674186024259,22.99683394061531],
    [-34.02767826828479,22.99962889218942],
    [-34.02893534416014,23.00180992698247],
    [-34.0287983611977,23.00430694090424],
    [-34.0308442779283,23.00621075691346],
    [-34.03290581761561,23.00910325783968],
    [-34.03577939266041,23.01099521721582],
    [-34.03876754652606,23.01419402355583],
    [-34.03888856379825,23.01619720043956],
    [-34.0371083762928,23.02883045048388],
    [-34.0365663404644,23.03175678766775],
    [-34.03701746133076,23.03592441035531],
    [-34.03680970458596,23.03839416047661],
    [-34.03958872731653,23.04328669761886],
    [-34.03944352669401,23.04237603781039],
    [-34.03679710248611,23.03828680057979],
    [-34.03689590011969,23.03567389310587],
    [-34.03651496748975,23.03157749554119],
    [-34.03724631968738,23.02318431593546],
    [-34.03885208222907,23.01575353101027],
    [-34.03641109321609,23.01127483014226],
    [-34.03143503580537,23.00669307615301],
    [-34.02869178067404,23.00357848671457],
    [-34.02770809744968,22.99969470269756],
    [-34.02709014232867,22.99501078404175],
    [-34.0331779723576,22.99036751059617],
    [-34.03586940150242,22.98867869877886],
    [-34.03685178661055,22.98222223130858],
    [-34.03776000666527,22.97413865032982],
    [-34.03188577566326,22.96859129050177],
    [-34.02972864720854,22.95871830362406],
    [-34.02881232702103,22.95334803080662],
    [-34.02868607793539,22.94684319436344],
    [-34.03238052088054,22.94023977312366],
    [-34.03521425504698,22.93988864451477],
    [-34.03836882888972,22.93875683247642],
    [-34.03922380951933,22.93359667906985],
    [-34.0458237034614,22.93726471311797],
    [-34.05252318660339,22.95141717604992],
    [-34.05921356042409,22.95139801698397],
    [-34.06557926318394,22.94898972836076],
    [-34.06956546634173,22.94893403563883],
    [-34.07569759474289,22.95336619910679],
    [-34.08308547071676,22.95855369714326],
    [-34.07155702456815,22.95094104010719],
    [-34.06801990575537,22.94809155580184],
    [-34.06207375072486,22.94984096493753],
    [-34.05572194898941,22.95313535245831],
    [-34.04790753345382,22.94316969081688],
    [-34.04199555304807,22.93386416194134],
    [-34.03832211751202,22.93590238341067],
    [-34.03694498573327,22.94018245808611],
    [-34.03378877139772,22.93969012020246],
    [-34.0311962753273,22.94066996374978],
    [-34.02905351584824,22.95051504303035],
    [-34.02870910846565,22.95593271351455],
    [-34.03170541980287,22.96678907068459],
    [-34.03277240113742,22.97006366528884],
    [-34.03836843258394,22.97615856595599],
    [-34.03632808165237,22.98468276473566],
    [-34.03468112136061,22.99008239766151],
    [-34.02856237994196,22.99349650373428],
    [-34.0267244641921,22.99709550867525],
    [-34.02881629003625,23.00146187375688],
    [-34.02932030623746,23.00531609359535],
    [-34.03291687158426,23.00913769187718],
    [-34.03848538310867,23.01359757332541],
    [-34.03839649686016,23.01818186096642],
    [-34.03701251686392,23.02994362082465],
    [-34.03645642500904,23.03362210226456],
    [-34.03700897212946,23.03730480780943],
    [-34.03842227493923,23.04090078043314],
    [-34.03864862038029,23.04375705876552],
    [-34.0368741,23.0376726],
    [-34.0369693,23.0370322],
    [-34.0369323,23.0356994],
    [-34.0364378,23.0327326],
    [-34.036851,23.0307289],
    [-34.0371322,23.0282952],
    [-34.0375264,23.0223691],
    [-34.0388307,23.0160115],
    [-34.0388718,23.0149179],
    [-34.0387675,23.0141466],
    [-34.0383998,23.01331709999999],
    [-34.0378049,23.0125536],
    [-34.036909,23.0117349],
    [-34.035025,23.010574],
    [-34.0333395,23.0096288],
    [-34.032255,23.0077551],
    [-34.0311634,23.0064432],
    [-34.029997,23.0057666],
    [-34.0289656,23.0047995],
    [-34.0287069,23.0033989],
    [-34.0289,23.0019598],
    [-34.0287261,23.0011242],
    [-34.0275946,22.9995088],
    [-34.0271319,22.99837],
    [-34.0267628,22.9968058],
    [-34.026795,22.9958531],
    [-34.0273213,22.9946495],
    [-34.0281363,22.993859],
    [-34.0312461,22.9915643],
    [-34.0326768,22.9905934],
    [-34.0342693,22.9902196],
    [-34.0353384,22.9896191],
    [-34.0359719,22.9881474],
    [-34.0361402,22.9865223],
    [-34.0380404,22.9779582],
    [-34.03835,22.9757203],
    [-34.0379615,22.9744336],
    [-34.037459,22.9737036],
    [-34.0345808,22.9713711],
    [-34.0325418,22.9697724],
    [-34.0318425,22.9683149],
    [-34.0317144,22.9668152],
    [-34.0300729,22.9596703],
    [-34.0295178,22.9580786],
    [-34.0289566,22.9567411],
    [-34.0286968,22.95554],
    [-34.0287048,22.9544276],
    [-34.0289274,22.9529647],
    [-34.0290723,22.9506096],
    [-34.0287154,22.9473194],
    [-34.0288169,22.946165],
    [-34.0297957,22.9438994],
    [-34.0312747,22.9404206],
    [-34.0323622,22.9385652],
    [-34.0337576,22.9367584],
    [-34.0343059,22.9349348],
    [-34.0352407,22.9325665],
    [-34.0362326,22.92993329999999],
    [-34.036823,22.9277318],
    [-34.0379014,22.9260518],
    [-34.0392843,22.9239264],
    [-34.0394892,22.9217764],
    [-34.0393862,22.91970510000001],
    [-34.0391436,22.9171249],
    [-34.0385331,22.9141805],
    [-34.0377778,22.91082949999999],
    [-34.0367817,22.9068867],
    [-34.0360881,22.9046507],
    [-34.0357265,22.9036571],
    [-34.0330123,22.8971508],
    [-34.0309511,22.892265],
    [-34.0300007,22.8899898],
    [-34.0280068,22.8851757],
    [-34.027591,22.8841645],
    [-34.0268512,22.8817903],
    [-34.026525,22.8799499],
    [-34.0260672,22.8765833],
    [-34.024959,22.8684233],
    [-34.0242853,22.8615338],
    [-34.0240492,22.8580315],
    [-34.0235043,22.8532343],
    [-34.0231514,22.8506147],
    [-34.0229901,22.8492441],
    [-34.0226169,22.842559],
    [-34.0223836,22.838663],
    [-34.0222251,22.8350961],
    [-34.0218905,22.8292151],
    [-34.0212875,22.8255963],
    [-34.0195555,22.8189064],
    [-34.0186684,22.8154577],
    [-34.0168965,22.8089376],
    [-34.0134054,22.8001294],
    [-34.0107095,22.7932447],
    [-34.0094594,22.78594989999999],
    [-34.0092889,22.7838037],
    [-34.0093278,22.7807434],
    [-34.009332,22.7763831],
    [-34.0093163,22.769199],
    [-34.0093378,22.7621086],
    [-34.0093834,22.7549754],
    [-34.0093586,22.7526379],
    [-34.0091365,22.7504725],
    [-34.0088611,22.7492144],
    [-34.0081121,22.746837],
    [-34.0065122,22.7416037],
    [-34.0056779,22.7391697],
    [-34.005398,22.7378971],
    [-34.0052862,22.7372392],
    [-34.0051519,22.7359684],
    [-34.0048782,22.7331046],
    [-34.0046297,22.7319042],
    [-34.0037538,22.7290506],
    [-34.0033262,22.7274804],
    [-34.003248,22.7267607],
    [-34.0041008,22.7229488],
    [-34.0043109,22.7208859],
    [-34.0042514,22.7195862],
    [-34.0041613,22.7177674],
    [-34.0040068,22.71529930000001],
    [-34.0034517,22.7126207],
    [-34.0033646,22.7113293],
    [-34.0034451,22.7081338],
    [-34.0033385,22.7068424],
    [-34.0029178,22.7028404],
    [-34.0022225,22.6961002],
    [-34.0015152,22.6895546],
    [-34.0010698,22.6853051],
    [-34.0003736,22.6811108],
    [-33.998101,22.675456],
    [-33.9975125,22.6739739],
    [-33.9972739,22.6725804],
    [-33.9973237,22.671563],
    [-33.9975052,22.6707247],
    [-33.9980398,22.6693512],
    [-33.998455,22.66841],
    [-33.9996638,22.6657244],
    [-34.0001702,22.6641736],
    [-34.0002797,22.6612124],
    [-34.0000743,22.6575592],
    [-34.0001758,22.6547633],
    [-34.0008242,22.6495833],
    [-34.0013052,22.6459083],
    [-34.0015651,22.6433713],
    [-34.0013809,22.6410036],
    [-34.0009872,22.6381907],
    [-34.0004621,22.6344355],
    [-33.9998777,22.6303728],
    [-33.9992335,22.6256635],
    [-33.99827,22.6185134],
    [-33.997879,22.6151216],
    [-33.997919,22.6150739],
    [-33.9979411,22.6150115],
    [-33.9979419,22.6149437],
    [-33.9979197,22.6148776],
    [-33.9978801,22.6148295],
    [-33.9977134,22.6142719],
    [-33.9972026,22.6106365],
    [-33.9968588,22.6081138],
    [-33.9967147,22.6063796],
    [-33.9968583,22.6039965],
    [-33.9973015,22.6013772],
    [-33.9974455,22.6000041],
    [-33.9973158,22.5987579],
    [-33.9965879,22.5971193],
    [-33.995983,22.5960387],
    [-33.9955485,22.5949597],
    [-33.99528,22.5935649],
    [-33.9951573,22.5909315],
    [-33.9949551,22.5829758],
    [-33.9951615,22.578923],
    [-33.9952076,22.576861],
    [-33.9947554,22.5751328],
    [-33.9944292,22.5741237],
    [-33.9943959,22.5733026],
    [-33.9946111,22.5698033],
    [-33.9947004,22.5682824],
    [-33.9947916,22.5671188],
    [-33.9949486,22.5648113],
    [-33.9949486,22.5637357],
    [-33.9951874,22.5630297],
    [-33.9955092,22.5625597],
    [-33.9962829,22.5620247],
    [-33.9969358,22.561498],
    [-33.9971787,22.5612271],
    [-33.9976096,22.5606661],
    [-33.997671,22.5601979],
    [-33.9974793,22.5595654],
    [-33.9972249,22.5591287],
    [-33.9968765,22.5585412],
    [-33.9961276,22.5572843],
    [-33.995736,22.5565766],
    [-33.9954034,22.5558076],
    [-33.99486,22.5544377],
    [-33.9946901,22.5540557],
    [-33.9943105,22.5534376],
    [-33.9938524,22.5529344],
    [-33.9933019,22.5525344],
    [-33.992889,22.5523257],
    [-33.9921285,22.5520548],
    [-33.9915021,22.5519482],
    [-33.9904953,22.5518811],
    [-33.9901316,22.5519277],
    [-33.9889922,22.5525902],
    [-33.9883438,22.5526482],
    [-33.9879146,22.552341],
    [-33.9876804,22.5519835],
    [-33.9875209,22.551274],
    [-33.9877092,22.5505282],
    [-33.9880825,22.5501133],
    [-33.988659,22.5496845],
    [-33.9892928,22.5492885],
    [-33.9900373,22.549076],
    [-33.990425,22.5487357],
    [-33.9905367,22.5482279],
    [-33.9903681,22.5476697],
    [-33.9897167,22.5470061],
    [-33.9890023,22.5463477],
    [-33.9876605,22.5451907],
    [-33.9871711,22.5446762],
    [-33.9868004,22.5438542],
    [-33.9867037,22.5434515],
    [-33.9867544,22.5425659],
    [-33.9870097,22.541833],
    [-33.9873301,22.5411731],
    [-33.9877885,22.5405652],
    [-33.9885144,22.5399254],
    [-33.9891092,22.5394372],
    [-33.9895973,22.5389419],
    [-33.9904171,22.5376676],
    [-33.9916226,22.5355547],
    [-33.9927556,22.5337348],
    [-33.9933144,22.5325391],
    [-33.994672,22.5279617],
    [-33.9949254,22.5267246],
    [-33.9949303,22.5248894],
    [-33.9945452,22.5231558],
    [-33.9933701,22.5211752],
    [-33.9922074,22.5198841],
    [-33.9919707,22.5194528],
    [-33.9919147,22.5193559],
    [-33.9918305,22.5193117],
    [-33.9897027,22.517044],
    [-33.9872157,22.5140099],
    [-33.9863619,22.511695],
    [-33.9864993,22.5096559],
    [-33.9872207,22.5068043],
    [-33.9895902,22.5021268],
    [-33.9910604,22.4992073],
    [-33.993528,22.4943935],
    [-33.9940884,22.4932093],
    [-33.9947941,22.4914025],
    [-33.9950605,22.4899702],
    [-33.9953115,22.4863581],
    [-33.99562,22.4806206],
    [-33.9958675,22.4765182],
    [-33.9959367,22.474216],
    [-33.9957752,22.4633995],
    [-33.9958026,22.4610226],
    [-33.9963915,22.4569191],
    [-33.9971509,22.4522903],
    [-33.9983849,22.4451031],
    [-33.9991697,22.4404155],
    [-33.9997752,22.4378525],
    [-34.0008373,22.4354321],
    [-34.0018309,22.4336254],
    [-34.0061997,22.4258621],
    [-34.0070115,22.4242453],
    [-34.0075771,22.4227338],
    [-34.0082709,22.4193436],
    [-34.0087719,22.4163232],
    [-34.0092103,22.4137312],
    [-34.0096009,22.4102402],
    [-34.0096943,22.4076682],
    [-34.0096317,22.4054147],
    [-34.0094768,22.403523],
    [-34.0091096,22.400792],
    [-34.0088381,22.3988247],
    [-34.0085725,22.3967097],
    [-34.0085886,22.3942868],
    [-34.0088323,22.3929859],
    [-34.0092961,22.3911643],
    [-34.0098317,22.389191],
    [-34.0108582,22.3855137],
    [-34.0116062,22.382856],
    [-34.0127067,22.3800972],
    [-34.0149396,22.3758358],
    [-34.0179003,22.3701088],
    [-34.019537,22.3670151],
    [-34.0202686,22.3651489],
    [-34.0204437,22.3626849],
    [-34.020077,22.3596524],
    [-34.0201787,22.3577783],
    [-34.0205307,22.3556649],
    [-34.0210486,22.3525797],
    [-34.0215869,22.3500816],
    [-34.0222805,22.3481775],
    [-34.0227745,22.3461886],
    [-34.0228583,22.3452399],
    [-34.0229505,22.343717],
    [-34.023235,22.3389504],
    [-34.023341,22.3342985],
    [-34.0233614,22.331719],
    [-34.0240137,22.3287939],
    [-34.0243585,22.3275591],
    [-34.0249319,22.3255054],
    [-34.0259921,22.3218579],
    [-34.0276729,22.3160686],
    [-34.0284472,22.3134322],
    [-34.029253,22.3106273],
    [-34.0298418,22.3085713],
    [-34.0309473,22.3047645],
    [-34.0339766,22.29442],
    [-34.0347218,22.2930271],
    [-34.0358506,22.2917213],
    [-34.0372528,22.2906488],
    [-34.0401168,22.2898445],
    [-34.0412941,22.2896221],
    [-34.0425683,22.28891],
    [-34.0435695,22.2877502],
    [-34.044228,22.2861461],
    [-34.0443787,22.2848768],
    [-34.0440912,22.2828175],
    [-34.0431108,22.2803608],
    [-34.0429654,22.2793273],
    [-34.0430565,22.2782407],
    [-34.0433791,22.2772146],
    [-34.0443435,22.2753851],
    [-34.0446503,22.2739773],
    [-34.0444465,22.2714856],
    [-34.0443979,22.2698903],
    [-34.0445472,22.2691378],
    [-34.0463889,22.2661571],
    [-34.049012,22.2620621],
    [-34.0497553,22.2591279],
    [-34.0500332,22.256326],
    [-34.0502628,22.25273],
    [-34.0501054,22.2515932],
    [-34.0490525,22.2488425],
    [-34.0480718,22.2467687],
    [-34.0472763,22.2450997],
    [-34.0466252,22.2437291],
    [-34.0461151,22.2424146],
    [-34.0460139,22.2409049],
    [-34.0464992,22.2392882],
    [-34.0468318,22.2387761],
    [-34.0481825,22.2372819],
    [-34.0488003,22.2363429],
    [-34.0500467,22.2329751],
    [-34.0507891,22.2306865],
    [-34.051362,22.2290515],
    [-34.0525495,22.2266339],
    [-34.0541283,22.2238144],
    [-34.0562489,22.2200719],
    [-34.0575505,22.2174205],
    [-34.0580782,22.2138273],
    [-34.0591702,22.2040298],
    [-34.0593273,22.2029787],
    [-34.0603236,22.196252],
    [-34.0613145,22.1896287],
    [-34.0614711,22.1885836],
    [-34.0617623,22.1870815],
    [-34.0625985,22.1840224],
    [-34.0641734,22.1793684],
    [-34.0650575,22.1767554],
    [-34.0685369,22.1669914],
    [-34.0705039,22.1625983],
    [-34.0723066,22.1585913],
    [-34.0760108,22.1503846],
    [-34.0771983,22.1479149],
    [-34.0819607,22.1417484],
    [-34.0860491,22.1364385],
    [-34.0881994,22.1336297],
    [-34.0886152,22.1330834],
    [-34.0902866,22.1308822],
    [-34.0920669,22.1283129],
    [-34.0940119,22.124756],
    [-34.0948051,22.1233063],
    [-34.0954163,22.1222018],
    [-34.096005,22.1214171],
    [-34.0965504,22.1208518],
    [-34.0983273,22.1193729],
    [-34.101583,22.1167466],
    [-34.1048662,22.1140759],
    [-34.1065829,22.1127063],
    [-34.1076949,22.1118412],
    [-34.109018,22.1107175],
    [-34.1124904,22.1079423],
    [-34.1136341,22.1072413],
    [-34.1149657,22.1067655],
    [-34.1159321,22.1066032],
    [-34.1175184,22.1063685],
    [-34.1189265,22.1062343],
    [-34.1192968,22.1062003],
    [-34.1206072,22.1060846],
    [-34.1218128,22.1059766],
    [-34.1233454,22.1055793],
    [-34.1259758,22.1045207],
    [-34.1290809,22.1032388],
    [-34.130114,22.102846],
    [-34.1337862,22.1019638],
    [-34.1356992,22.1015682],
    [-34.1381103,22.1010427],
    [-34.1422365,22.1001366],
    [-34.1462697,22.0992007],
    [-34.1488242,22.0987323],
    [-34.1514592,22.0990403],
    [-34.1532619,22.0992554],
    [-34.1563838,22.0996164],
    [-34.1582086,22.0995762],
    [-34.1596705,22.0987178],
    [-34.160375,22.09784],
    [-34.1615148,22.0953743],
    [-34.1631349,22.0917379],
    [-34.165715,22.0860104],
    [-34.1666033,22.0840349],
    [-34.1687248,22.0793281],
    [-34.1699993,22.0765024],
    [-34.1713216,22.0736115],
    [-34.1733358,22.0692395],
    [-34.1759955,22.066018],
    [-34.1794813,22.0625868],
    [-34.1817146,22.0603929],
    [-34.1826541,22.0592349],
    [-34.1835642,22.0571286],
    [-34.1837215,22.0562825],
    [-34.1835952,22.0534274],
    [-34.181922,22.0488579],
    [-34.1815227,22.0460619],
    [-34.1814292,22.0447963],
    [-34.1813479,22.0412086],
    [-34.1811958,22.0351055],
    [-34.1811428,22.0331498],
    [-34.1810338,22.0284669],
    [-34.180753,22.0184874],
    [-34.1805094,22.0105753],
    [-34.1803205,22.0043206],
    [-34.1800782,21.9962481],
    [-34.1798585,21.9907734],
    [-34.1796744,21.9832441],
    [-34.1795065,21.9768125],
    [-34.1793787,21.9722919],
    [-34.1790932,21.9633476],
    [-34.1790894,21.9609517],
    [-34.1788176,21.9536844],
    [-34.178653,21.9470027],
    [-34.1779882,21.9240891],
    [-34.1778326,21.9186994],
    [-34.177741,21.9154401],
    [-34.1776981,21.9121956],
    [-34.1775529,21.9088633],
    [-34.1773392,21.9010515],
    [-34.1775169,21.8961971],
    [-34.1779395,21.8924753],
    [-34.1786376,21.8871059],
    [-34.1793874,21.8814491],
    [-34.1802139,21.8774013],
    [-34.181795,21.8718232],
    [-34.183151,21.8670988],
    [-34.1844192,21.8627008],
    [-34.1889593,21.8469516],
    [-34.1919805,21.8364323],
    [-34.193724,21.8303445],
    [-34.1962731,21.8215021],
    [-34.1968908,21.8189478],
    [-34.1972677,21.8154063],
    [-34.1970513,21.8110063],
    [-34.1966317,21.8075642],
    [-34.1962621,21.8043629],
    [-34.1956308,21.7990604],
    [-34.1949784,21.7937223],
    [-34.1941901,21.7874026],
    [-34.1934819,21.7820303],
    [-34.1923724,21.7795932],
    [-34.19184,21.7784291],
    [-34.1915136,21.7771885],
    [-34.1912415,21.775144],
    [-34.1910422,21.7731078],
    [-34.1907624,21.7705116],
    [-34.1904091,21.7668921],
    [-34.1899372,21.7650726],
    [-34.1893211,21.763998],
    [-34.1882259,21.7628461],
    [-34.1872087,21.7614485],
    [-34.1863566,21.7590968],
    [-34.1861914,21.7570217],
    [-34.1860339,21.753111],
    [-34.1859949,21.7515897],
    [-34.1859184,21.7494174],
    [-34.1858701,21.7471438],
    [-34.18608,21.7448689],
    [-34.1865564,21.7425486],
    [-34.1870806,21.7402426],
    [-34.1878857,21.7365909],
    [-34.1891685,21.7309689],
    [-34.1901646,21.7265646],
    [-34.1924118,21.7163555],
    [-34.1944907,21.7070114],
    [-34.1953511,21.7026109],
    [-34.1970233,21.6932587],
    [-34.1982602,21.6862348],
    [-34.1994185,21.6799558],
    [-34.2003307,21.674774],
    [-34.2014045,21.6687988],
    [-34.2022274,21.6642444],
    [-34.2029141,21.660419],
    [-34.2037838,21.6554904],
    [-34.2047159,21.6510748],
    [-34.2053331,21.6485167],
    [-34.2070032,21.6414916],
    [-34.2087807,21.6341376],
    [-34.2098191,21.6294812],
    [-34.2108049,21.6254189],
    [-34.2111163,21.6231049],
    [-34.2113305,21.6172398],
    [-34.2115566,21.6113528],
    [-34.2116731,21.6084439],
    [-34.2117746,21.6065706],
    [-34.2120396,21.598851],
    [-34.2122532,21.5927665],
    [-34.2123862,21.5893006],
    [-34.2126064,21.5848344],
    [-34.212747,21.5792331],
    [-34.2129863,21.5734605],
    [-34.2131971,21.5678405],
    [-34.2133726,21.564298],
    [-34.2132146,21.5598085],
    [-34.2125727,21.5557525],
    [-34.2119745,21.553186],
    [-34.2112172,21.5508043],
    [-34.2096013,21.5461993],
    [-34.2070505,21.5387568],
    [-34.2058943,21.5334372],
    [-34.2036433,21.5221948],
    [-34.2020449,21.5142803],
    [-34.1997978,21.5031584],
    [-34.1964784,21.4866552],
    [-34.1947654,21.4782091],
    [-34.1924366,21.4665854],
    [-34.1920031,21.4641705],
    [-34.1916826,21.4603632],
    [-34.1912619,21.4561646],
    [-34.189731,21.4509434],
    [-34.1887082,21.4478578],
    [-34.1873271,21.4413275],
    [-34.1855827,21.4327612],
    [-34.1843354,21.4257902],
    [-34.1833679,21.418285],
    [-34.1825269,21.4152395],
    [-34.1808636,21.409479],
    [-34.1791417,21.40099],
    [-34.1782569,21.3966699],
    [-34.1766409,21.3888393],
    [-34.175184,21.3816502],
    [-34.1740436,21.3759359],
    [-34.1735952,21.3733812],
    [-34.1730282,21.3687135],
    [-34.172364,21.3639717],
    [-34.1698165,21.3441971],
    [-34.169672,21.3410459],
    [-34.169939,21.3339966],
    [-34.1702868,21.3256882],
    [-34.1704875,21.3216169],
    [-34.1703288,21.3200047],
    [-34.1700551,21.3190174],
    [-34.1695932,21.3180467],
    [-34.1686041,21.3167732],
    [-34.1675631,21.3155727],
    [-34.1664107,21.3142481],
    [-34.1651546,21.3128305],
    [-34.1640461,21.311527],
    [-34.1633245,21.3102956],
    [-34.1626754,21.3082027],
    [-34.1622456,21.3066031],
    [-34.161056,21.3043048],
    [-34.1603132,21.3032277],
    [-34.1594788,21.3024062],
    [-34.1581604,21.3016898],
    [-34.157397,21.3015312],
    [-34.156533,21.3017194],
    [-34.155767,21.3020917],
    [-34.1546473,21.3024686],
    [-34.1537776,21.3023236],
    [-34.152943,21.3017718],
    [-34.1522698,21.3006894],
    [-34.1508793,21.2975234],
    [-34.1493149,21.2941487],
    [-34.1470647,21.2895783],
    [-34.1462639,21.2879567],
    [-34.1458906,21.2865933],
    [-34.1459223,21.2856956],
    [-34.1464444,21.2841833],
    [-34.1468518,21.2829761],
    [-34.1469159,21.2821743],
    [-34.1467413,21.2811613],
    [-34.1462848,21.2802689],
    [-34.1456165,21.2796665],
    [-34.1446073,21.2792671],
    [-34.1429689,21.2792411],
    [-34.140749,21.2790388],
    [-34.139064,21.2784255],
    [-34.1352101,21.2768884],
    [-34.1339915,21.2762861],
    [-34.1325252,21.2751734],
    [-34.1308127,21.2737566],
    [-34.130286,21.2729211],
    [-34.1299133,21.2713265],
    [-34.1294048,21.2679222],
    [-34.1291923,21.2665041],
    [-34.1287941,21.2652182],
    [-34.1279922,21.2637886],
    [-34.1266196,21.2623959],
    [-34.1235165,21.2599166],
    [-34.1197578,21.2569549],
    [-34.1168506,21.2547955],
    [-34.114313,21.2539385],
    [-34.1108768,21.2540757],
    [-34.1083868,21.2543433],
    [-34.1066547,21.2542291],
    [-34.10378,21.2530171],
    [-34.1015804,21.2520053],
    [-34.0998606,21.2518223],
    [-34.0982248,21.2523499],
    [-34.0965959,21.2529483],
    [-34.0957554,21.2529735],
    [-34.0947247,21.2524546],
    [-34.093776,21.2513115],
    [-34.0928948,21.2518707],
    [-34.0920576,21.2531509],
    [-34.0916779,21.2552561],
    [-34.0911264,21.2551324],
    [-34.08900617063689,21.25643775718293],
    [-34.08828299517322,21.25680768395564],
    [-34.08926062397374,21.25754416482625],
    [-34.08931122012537,21.25657430613263],
]
