import './SocialMedia.scss';
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import linkedin from '../../assets/images/linkedin.png';
import twitter from '../../assets/images/twitter.png';
import tiktok from '../../assets/images/tiktok.png';

function SocialMedia() {

    return (
        <div className="c-social-media">
            <a href="https://www.facebook.com/solarteam.be">
                <img src={facebook} alt="Agoria Solar Team Facebook page" />
            </a>

            <a href="https://www.instagram.com/solarteam_be/">
                <img src={instagram} alt="Agoria Solar Team Instagram page" />
            </a>

            <a href="https://www.linkedin.com/company/solar-team/">
                <img src={linkedin} alt="Agoria Solar Team Linkedin" />
            </a>

            <a href="https://www.tiktok.com/@solarteam_be">
                <img src={tiktok} alt="Agoria Solar Team Tiktok" />
            </a>

            <a href="https://twitter.com/solarteam_be">
                <img src={twitter} alt="Agoria Solar Team Twitter" />
            </a>
        </div>
    ); 
}
export default SocialMedia;
