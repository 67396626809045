import { useEffect, useRef } from 'react';

interface IUseInterval {
    (callback: () => void, interval: number): void;
}

const useInterval: IUseInterval = (callback, interval) => {
    const savedCallback = useRef<(() => void) | null>(null);

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval
    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        // tick();
        if (interval !== null) {
            let id = setInterval(tick, interval);
            return () => clearInterval(id);
        }
    }, [callback, interval]);
}

export default useInterval;