import OfflinePlaceholder from '../../OfflinePlaceholder/OfflinePlaceholder';
import Timestamp from '../../Timestamp/Timestamp';
import './DistanceTile.scss';

interface DistanceTileProps {
  lastUpdate?: string;
  distance?: number;
  distanceVisible?: boolean;
  odo?: number;
  odoVisible?: boolean;
}

function DistanceTile(props: DistanceTileProps) {
  const getOdoTemplate = () => {
    if (!props.odo && props.odoVisible === undefined ) { // api is loading; both are undefined
      return <div className="c-tile-value -placeholder"/>;
    }
    if (props.odo && props.odoVisible) { // data and visible
      return <div className="c-tile-value">{props.odo} km</div>
    }
    return <div className="c-tile-value">- km</div> // no data or !visible
  }

  const getRaceTotalTemplate = () => {
    if (!props.distance && props.distanceVisible === undefined ) { // api is loading; both are undefined
      return <div className="-total"/>;
    }
    if (props.distanceVisible && props.distance) { // data and visible
      return <span>{props.distance} km</span>
    }
    return <span>- km</span> // no data or !visible
  }

  const isTileDataLoading = () => {
    return props.odoVisible === undefined 
      && props.distanceVisible === undefined
      && !props.odo 
      && !props.distance;
  }

  const isTileOffline = () => {
    return (!props.odoVisible || !props.distanceVisible) && !isTileDataLoading();
  }

  return (
    <div className="-distance" tabIndex={0}>
      <div className='c-tile-title'>Distance</div>
      { isTileOffline() ? <OfflinePlaceholder /> : <Timestamp timestamp={props.lastUpdate} /> }
      { getOdoTemplate() }

      <div><span>Total: </span>{ getRaceTotalTemplate() }</div>
    </div>
  )
}
export default DistanceTile;
