import GeneralTile from '../GeneralTile/GeneralTile';

interface AltitudeTileProps {
  lastUpdate?: string;
  altitude?: number;
  visible?: boolean;
}

function AltitudeTile(props: AltitudeTileProps) {
  return (
    <GeneralTile
      className="-altitude" 
      label="Altitude"
      timestamp={props?.lastUpdate}
      value={props?.altitude}
      unit="m"
      visible={props.visible}
    />
  ); 
}
export default AltitudeTile;
