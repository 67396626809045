export const day4: [number, number][] = [
    [-30.59688154909268,25.5018870123315],
    [-30.59612465,25.50128174],
    [-30.59320641,25.49747276],
    [-30.59318161,25.49723434],
    [-30.58908463,25.49362946],
    [-30.58823586,25.49228477],
    [-30.58715438999999,25.48957253],
    [-30.5804348,25.48966026],
    [-30.57224655,25.49246788],
    [-30.55709076,25.4974308],
    [-30.55475807,25.49687195],
    [-30.55300713,25.49546623000001],
    [-30.55301666,25.49491119],
    [-30.55326462,25.49393845],
    [-30.55311966,25.49154854],
    [-30.55358124,25.48952103],
    [-30.57054329,25.4557209],
    [-30.58555794,25.41746712],
    [-30.59152412,25.40337563],
    [-30.60453415,25.38937187],
    [-30.61078453,25.38189697],
    [-30.61804771,25.36279869],
    [-30.61994934000001,25.35148621],
    [-30.62071991,25.34385109],
    [-30.61755753,25.30891991],
    [-30.6163559,25.28962517],
    [-30.62279701,25.26906013],
    [-30.63886642,25.23047066],
    [-30.65633202,25.19503593],
    [-30.67900848,25.16476249999999],
    [-30.69893074,25.12884903],
    [-30.71194649,25.10082436],
    [-30.72602844,25.08648491],
    [-30.73605156,25.08139992],
    [-30.73641586,25.08106422],
    [-30.75989151,25.05094528],
    [-30.78213692,25.01652718],
    [-30.80407333,24.98187447],
    [-30.82522964,24.9466629],
    [-30.84474182,24.91063881],
    [-30.86454964,24.8742733],
    [-30.88508797,24.83845329],
    [-30.90521049,24.80237198],
    [-30.92133713,24.76360512],
    [-30.93740463,24.72481918],
    [-30.9520092,24.68549728],
    [-30.95224953,24.68479156],
    [-30.96611786,24.64554405],
    [-30.98253059,24.60686493],
    [-31.0048542,24.57259178],
    [-31.02497673,24.54038811],
    [-31.0414238,24.50265694],
    [-31.0618782,24.46668625],
    [-31.07151413,24.44920349],
    [-31.072649,24.43575287],
    [-31.07325363,24.43474007],
    [-31.07453728,24.43325806],
    [-31.07350731,24.43061256],
    [-31.07365799,24.42983627],
    [-31.07393646,24.42992401],
    [-31.07999420000001,24.43913841000001],
    [-31.08224297,24.4503727],
    [-31.08474922,24.45816994],
    [-31.08917046,24.46471977],
    [-31.10674286,24.48144913],
    [-31.13545609,24.5087471],
    [-31.13812447,24.51301956],
    [-31.14793015,24.54888725],
    [-31.16400909,24.58143044],
    [-31.16728973,24.58505249],
    [-31.18129539,24.61470795],
    [-31.19973183,24.64896202],
    [-31.22004509,24.68511009],
    [-31.23919296,24.71923255999999],
    [-31.25807953,24.75318527],
    [-31.26808357,24.78809357],
    [-31.27970314,24.82909584],
    [-31.28341675,24.84022141],
    [-31.29992867,24.86198807],
    [-31.30033875,24.86408234],
    [-31.29951668,24.90497208],
    [-31.29953194,24.91246796],
    [-31.30048943,24.91449547],
    [-31.31177902,24.92263031],
    [-31.31321335000001,24.92487144],
    [-31.31496048,24.92575836],
    [-31.32023048,24.92467117],
    [-31.32197189,24.92561531],
    [-31.32465935,24.92835426],
    [-31.32534409000001,24.93024635],
    [-31.32505035,24.93508148],
    [-31.32439041,24.93745995],
    [-31.32476807,24.93963814],
    [-31.32612801,24.94120407],
    [-31.33074188,24.94369316],
    [-31.33230972,24.94600296],
    [-31.33508492,24.9537487],
    [-31.33367729,24.96251106],
    [-31.33160019,24.97197533],
    [-31.33008575,24.97399902],
    [-31.34007454,24.98621178],
    [-31.35565758,25.00755501],
    [-31.35927773,25.01447868],
    [-31.36423302,25.01840590999999],
    [-31.37274933,25.02453232],
    [-31.37446785,25.02758789],
    [-31.37574387,25.03250313],
    [-31.37714577,25.03394127],
    [-31.37890816,25.03443146],
    [-31.38068199,25.03392792],
    [-31.38302803,25.03243064999999],
    [-31.38926315,25.03050613],
    [-31.42519951,25.02362633],
    [-31.46212387,25.01892853],
    [-31.46525764,25.01835251],
    [-31.46714592,25.01700592],
    [-31.47112083,25.01231003],
    [-31.4737854,25.01110077],
    [-31.48138237,25.00984192],
    [-31.48259544,25.00999451],
    [-31.48271942,25.00983429],
    [-31.48305511,25.00919533],
    [-31.48806763,25.00772667],
    [-31.48973465,25.00731087],
    [-31.48916817,25.00394058],
    [-31.48951912,25.00375175],
    [-31.49129868,25.00329018],
    [-31.49133492,25.0030365],
    [-31.49115753,25.00213814],
    [-31.49094391,25.00207901],
    [-31.49045760555849,25.00204435396466],
    [-31.49070416203568,25.00221126761519],
    [-31.49085999,25.00214958],
    [-31.49134297518487,25.00298356949943],
    [-31.4912262,25.00326729],
    [-31.48917389,25.00382042],
    [-31.48974037,25.00722885],
    [-31.4888382,25.00751305],
    [-31.48326492,25.00897598],
    [-31.48281288,25.00951385],
    [-31.48265839,25.00998497],
    [-31.48967934,25.01415825],
    [-31.4910202,25.01505089],
    [-31.50203514,25.02216339],
    [-31.50413704,25.02233124],
    [-31.51460838,25.01928329],
    [-31.51698494,25.01907921],
    [-31.51844406,25.01980782],
    [-31.53093719,25.0419178],
    [-31.53695107,25.04794312],
    [-31.56184959,25.06567001000001],
    [-31.58353615,25.08140564],
    [-31.59536934,25.09150314],
    [-31.60039711,25.09575272],
    [-31.59929848,25.09478188],
    [-31.58166695,25.07985115],
    [-31.54999924,25.0572319],
    [-31.53153229,25.04269791],
    [-31.52331543,25.02843475],
    [-31.51794624,25.01891899],
    [-31.51618003999999,25.01760483],
    [-31.51515198,25.01721191],
    [-31.51461411,25.01696014],
    [-31.5143528,25.01643181],
    [-31.51466179,25.01576042],
    [-31.51748085,25.01592636],
    [-31.51798058,25.01662254],
    [-31.51797676,25.01700211],
    [-31.51744652,25.01771927],
    [-31.50462341,25.02219582],
    [-31.50244141,25.02223587],
    [-31.49383354,25.01690483],
    [-31.48300743,25.00997925],
    [-31.48310661,25.00920296],
    [-31.48951912,25.00735283],
    [-31.48972892999999,25.00728798],
    [-31.4891777,25.00392723],
    [-31.49127388,25.00329018],
    [-31.49116516,25.00219917],
    [-31.49090576,25.00208664000001],
    [-31.49065208,25.00204468],
    [-31.49072567485387,25.00222112710155],
    [-31.49111748,25.0021534],
    [-31.49135399,25.0031929],
    [-31.49122238,25.00327301],
    [-31.48918152,25.00381088],
    [-31.48974037,25.00720406],
    [-31.48323822,25.00898552],
    [-31.48274231,25.0096817],
    [-31.48265839,25.00999832],
    [-31.48959923,25.01409531],
    [-31.50220108,25.02218819],
    [-31.50431061,25.02228355],
    [-31.51302338,25.01973534],
    [-31.54513931,25.01132393],
    [-31.56127739,25.0073204],
    [-31.58056068,24.9991684],
    [-31.61641884,24.98840523],
    [-31.65260506,24.979105],
    [-31.6871109,24.96492577],
    [-31.71529579,24.95825195],
    [-31.7221241,24.95292091],
    [-31.75306892,24.9297657],
    [-31.77824593,24.90392303],
    [-31.77999687,24.90058517],
    [-31.78203773,24.89852715],
    [-31.78475189,24.8975544],
    [-31.79226494,24.89723587],
    [-31.79395294,24.89604187],
    [-31.8024807,24.88396835],
    [-31.80921555,24.87936783],
    [-31.8117733,24.87670898],
    [-31.81781197,24.8650856],
    [-31.81817627,24.86011696],
    [-31.81930923,24.85837936],
    [-31.82399368,24.8554039],
    [-31.82688332,24.85517693],
    [-31.82908821,24.85600853],
    [-31.83143043999999,24.85622215],
    [-31.83282471,24.85727501],
    [-31.83574677,24.85866165],
    [-31.83785629,24.86214256],
    [-31.83868217,24.86364555],
    [-31.83905602,24.8653698],
    [-31.84103775,24.86673737],
    [-31.84207916,24.8699646],
    [-31.84419441,24.8711319],
    [-31.84474564,24.87277031],
    [-31.84456062,24.87453842],
    [-31.84406471,24.87625313],
    [-31.84518814,24.87978745],
    [-31.84651756,24.88080025],
    [-31.84806633,24.88038634999999],
    [-31.84928894,24.87891197],
    [-31.85489845,24.87110138],
    [-31.86616707,24.84058952],
    [-31.87386322,24.82604599],
    [-31.87603569,24.8242054],
    [-31.88056946,24.82232285],
    [-31.88219261,24.82029915],
    [-31.88659286,24.80642509],
    [-31.90646362,24.76985359],
    [-31.93367386,24.74052048],
    [-31.94673538,24.72645378],
    [-31.94935608,24.72556496],
    [-31.95936584,24.72706604],
    [-31.96096611,24.72589874],
    [-31.96222687,24.72426796],
    [-31.96418953,24.72356987],
    [-31.96683884,24.72158051],
    [-31.96959686,24.71614456],
    [-31.97040939,24.713974],
    [-31.97839355,24.70415878],
    [-31.98583031,24.69176865],
    [-32.00777435,24.65660477],
    [-32.03091049,24.62638474],
    [-32.03198242,24.62551308],
    [-32.04064178,24.61907768],
    [-32.05633545,24.61235809],
    [-32.06099701,24.60737038],
    [-32.06138229,24.60718155],
    [-32.08169556,24.60206413],
    [-32.09653091,24.60454559],
    [-32.10633087,24.60653877],
    [-32.11104584,24.60963249],
    [-32.1124115,24.6095314],
    [-32.11384583,24.60842896],
    [-32.11737442,24.60828018],
    [-32.1186142,24.60732841],
    [-32.12028885,24.60464287],
    [-32.13401794,24.57921028000001],
    [-32.13485718,24.5776844],
    [-32.13618469,24.57666206],
    [-32.1382637,24.57645607],
    [-32.14839554,24.5772686],
    [-32.15817642,24.57269478],
    [-32.18504715,24.56045341],
    [-32.21693802,24.54375267],
    [-32.2337532,24.5348835],
    [-32.24278641,24.53739929],
    [-32.24522018,24.53802299],
    [-32.24785233,24.53738403],
    [-32.24827576,24.53752708],
    [-32.24844742,24.53724098],
    [-32.25060654,24.52931023],
    [-32.2506485,24.52911758],
    [-32.24673462,24.52749061999999],
]
