export const day2: [number, number][] = [
    [-27.63218307,27.23525429],
    [-27.63218307,27.23525429],
    [-27.63218307,27.23525429],
    [-27.63225365,27.2337513],
    [-27.63249207,27.23343658],
    [-27.63232231,27.23316574],
    [-27.63170624,27.23324776],
    [-27.61907196,27.2337513],
    [-27.61821175,27.23428535],
    [-27.61772537,27.23497581],
    [-27.61792373999999,27.23516464],
    [-27.61945915,27.23562813],
    [-27.62082481,27.23681259],
    [-27.62161255,27.23845673],
    [-27.62482262,27.25644112],
    [-27.62474632,27.25658607],
    [-27.6224823,27.25679587999999],
    [-27.62170601,27.2563324],
    [-27.62168694,27.2554493],
    [-27.63653183,27.24841881],
    [-27.64134026,27.24767685],
    [-27.64503098,27.24759674],
    [-27.65114212,27.24779129],
    [-27.66692543,27.25404167],
    [-27.66958809,27.25346184],
    [-27.67892838,27.24865341],
    [-27.68187904,27.24677086],
    [-27.68918419,27.239645],
    [-27.69208336000001,27.23854065],
    [-27.71129608,27.23710632],
    [-27.72776985,27.2361412],
    [-27.74581718,27.23011589],
    [-27.78097343,27.22161674],
    [-27.81677437,27.21834946],
    [-27.85223961,27.21198463],
    [-27.88791656,27.20699501],
    [-27.91864586,27.2039299],
    [-27.92332458,27.20215034],
    [-27.957798,27.19051552],
    [-27.96514511,27.18824005],
    [-27.9687748,27.188694],
    [-27.97454643,27.19075203],
    [-27.97838974,27.19037819],
    [-27.99202156,27.18412209],
    [-28.02531242,27.16880989],
    [-28.05920029,27.15649033],
    [-28.07965469,27.14837456],
    [-28.09104729,27.13878441],
    [-28.0967617,27.13277054],
    [-28.11128235,27.12588501],
    [-28.13039589,27.12186241],
    [-28.16142082,27.11647987],
    [-28.18249893,27.10393333],
    [-28.1944828,27.1027813],
    [-28.22654724,27.08854485],
    [-28.22669792,27.08853149],
    [-28.24753952,27.08822441],
    [-28.2492733,27.08685684],
    [-28.25631332,27.07670784],
    [-28.25729942,27.07592963999999],
    [-28.28333473,27.06467819],
    [-28.28616714,27.06450844],
    [-28.31132507,27.06561661],
    [-28.34290504,27.06538582],
    [-28.34384346,27.06507492],
    [-28.37110329,27.05142021],
    [-28.40309715,27.0357399],
    [-28.42077446,27.03318977],
    [-28.43886948,27.03378868],
    [-28.46653175,27.0322876],
    [-28.46925354,27.03053474],
    [-28.47120285,27.027668],
    [-28.47202301,27.02576256],
    [-28.48108101,27.0060215],
    [-28.48477364,27.00271034],
    [-28.49569511,26.99858284],
    [-28.49887657,26.998106],
    [-28.4983387,27.00310326],
    [-28.49882507,27.00322151],
    [-28.50732231,27.00130272],
    [-28.50978279,27.00198746],
    [-28.51259232,27.00358963],
    [-28.51403427,27.0045929],
    [-28.51456642,27.00545692],
    [-28.51597214,27.01151657],
    [-28.51598167,27.01160049],
    [-28.51599512403708,27.01163760460769],
    [-28.51629448,27.01297951],
    [-28.51629448,27.01297951],
    [-28.51629829000001,27.01298523],
    [-28.5166111,27.01438713],
    [-28.51644135,27.01568604],
    [-28.51584053,27.01655388],
    [-28.49800682,27.02808571],
    [-28.49593353,27.03141403],
    [-28.49489021,27.0341568],
    [-28.49423266192287,27.03463849418811],
    [-28.49409294,27.03445053],
    [-28.4967556,27.0135994],
    [-28.49830437,27.00340462],
    [-28.50750351,27.00129889999999],
    [-28.50995445,27.00208282],
    [-28.51254654,27.00357056],
    [-28.51397514,27.0045414],
    [-28.514534,27.00541115],
    [-28.51602742787517,27.01177552578087],
    [-28.51599512403708,27.01163760460769],
    [-28.51629448,27.01297951],
    [-28.51629448,27.01297951],
    [-28.51629829000001,27.01298523],
    [-28.5166111,27.01438713],
    [-28.51644135,27.01568604],
    [-28.51584053,27.01655388],
    [-28.49800682,27.02808571],
    [-28.49593353,27.03141403],
    [-28.49489021,27.0341568],
    [-28.49423266192287,27.03463849418811],
    [-28.49409250070716,27.03532932911805],
    [-28.49450529077689,27.03766637677651],
    [-28.49583010332299,27.04124998696158],
    [-28.49897946664729,27.04966329836889],
    [-28.50104543461877,27.07096327790596],
    [-28.49976755968719,27.08716538416979],
    [-28.49755389888027,27.0937418559356],
    [-28.49783386103752,27.0940431984729],
    [-28.49776637152025,27.09374647037377],
    [-28.49771664951249,27.09343892132772],
    [-28.49782095011479,27.0929663458596],
    [-28.49905794523405,27.08930889927177],
    [-28.50116015965761,27.08270847287187],
    [-28.50158487746893,27.07666281008586],
    [-28.49984690172425,27.05874564801196],
    [-28.4978763851219,27.04543819683522],
    [-28.49513528257858,27.03962773987794],
    [-28.49409294,27.03445053],
    [-28.4967556,27.0135994],
    [-28.49830437,27.00340462],
    [-28.50750351,27.00129889999999],
    [-28.50995445,27.00208282],
    [-28.51254654,27.00357056],
    [-28.51397514,27.0045414],
    [-28.514534,27.00541115],
    [-28.51602742787517,27.01177552578087],
    [-28.51606416185832,27.01191061481947],
    [-28.51620483,27.01614761],
    [-28.51069069,27.02059555],
    [-28.49736023,27.02890014999999],
    [-28.4955616,27.0324192],
    [-28.49494171,27.03408241],
    [-28.49435806,27.03460884],
    [-28.49408722,27.0344696],
    [-28.4940567,27.03402901],
    [-28.49885368,26.99929047],
    [-28.49903297,26.9981575],
    [-28.4992733,26.99801826],
    [-28.50018883,26.99771118],
    [-28.50584602,26.99484444],
    [-28.52252388,26.98881912],
    [-28.55055428,26.97841263],
    [-28.55395508,26.97424698],
    [-28.56507874,26.95867157],
    [-28.57771873,26.95157433],
    [-28.60766029,26.93519974],
    [-28.6098423,26.93279266],
    [-28.63294029,26.90169334],
    [-28.65406418,26.87037086],
    [-28.67194939,26.84325981],
    [-28.6899128,26.815588],
    [-28.71142769,26.78243256],
    [-28.73822784,26.75513649],
    [-28.76077843,26.73389244],
    [-28.78430748,26.70886803],
    [-28.79855347,26.69106102],
    [-28.81406021,26.6703167],
    [-28.8264389,26.64888763],
    [-28.83158493,26.6334095],
    [-28.84470749,26.59391594],
    [-28.85781479,26.55441475],
    [-28.8732357,26.51604462],
    [-28.88484764,26.49024582],
    [-28.89251518,26.48031235],
    [-28.91622162,26.44783592],
    [-28.93849373,26.41396523],
    [-28.95345879,26.39016151],
    [-28.95690536,26.37781334],
    [-28.96753311,26.33734322],
    [-28.97618484,26.29722023],
    [-28.97942162,26.28378868],
    [-28.98157692,26.28102684],
    [-29.00058174,26.26969147],
    [-29.02379608,26.25640488],
    [-29.02721977,26.25163651],
    [-29.02882576,26.24793053],
    [-29.04389191,26.21252441],
    [-29.04584694,26.21064568],
    [-29.04891205,26.20928574],
    [-29.05505943,26.20817757],
    [-29.05789757,26.20609093],
    [-29.05990028,26.20288849],
    [-29.0635128,26.1940937],
    [-29.07199669,26.18433189],
    [-29.08839035,26.17289352],
    [-29.08963203,26.17281914],
    [-29.0906601,26.1729393],
    [-29.09138489,26.17256546],
    [-29.09208679,26.17323494],
    [-29.10235214,26.18477821],
    [-29.10264015,26.18462181],
    [-29.10378838,26.18323135],
    [-29.10378838,26.18323135],
]
