import OfflinePlaceholder from '../../OfflinePlaceholder/OfflinePlaceholder';
import Timestamp from '../../Timestamp/Timestamp';

interface LoopsTileProps {
  lastUpdate?: string;
  loops?: number;
  visible?: boolean;
}

function LoopsTile(props: LoopsTileProps) {
  const isTileDataLoading = () => {
    return props.visible === undefined 
      && props.loops === undefined;
  }

  const isTileOffline = () => {
    return !props.visible && !isTileDataLoading();
  }

  const getTemplate = () => {
    if (isTileDataLoading()) {
      return <div className="c-tile-value -placeholder"/>;
    }
    else if (props.loops && props.visible && props.loops !== 0) {
      return <div className='c-tile-value'>{props.loops} loops</div>
    }
    else if (props.loops === 0) {
      return <div className='c-tile-value'>0 loops</div>
    }
    return <div className='c-tile-value'>- loops</div>;
  }

  return (
    <div className="-loops general-tile" tabIndex={0}>
      <div className='c-tile-title'>Loops driven</div>
      { isTileOffline() ? <OfflinePlaceholder /> : <Timestamp timestamp={props.lastUpdate} />}
      { getTemplate() }
      <div><a href="https://solarteam.webhero.be/en/races/sasol-solar-challenge"><span className="-underline-on-hover">More info</span></a> →</div>
    </div>
  );
}
export default LoopsTile;
