import { MetricsDto } from '../models/MetricsDto';
import axios from 'axios';
import { StartTimeDto } from '../models/StartTimeDto';
import { StatusDto } from '../models/StatusDto';
import { DriverDto } from '../models/DriverDto';
import { RankingDto } from '../models/RankingDto';
import { LocationDto } from '../models/LocationDto';

import * as nacl from 'tweetnacl';
import { Buffer } from 'buffer/';

const utils = require('tweetnacl-util')
const encodeBase64 = utils.encodeBase64
const secret_key = 'fkdmlsqjfeimjsckljmqs85fsee843f8'

const MOCK = false; // For testing purposes. TODO: remove

const AxiosInstance = axios.create({
  baseURL: `https://live.solarteam.be/bff/`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

function encrypt_data (data: string) {
  // Our nonce must be a 24 bytes Buffer (or Uint8Array)
  const nonce = nacl.randomBytes(24)
  // Our secret key must be a 32 bytes Buffer (or Uint8Array)
  const secretKey = Buffer.from(secret_key, 'utf8')
  // Make sure your data is also a Buffer of Uint8Array
  const secretData = Buffer.from(data, 'utf8')
  const encrypted = nacl.secretbox(secretData, nonce, secretKey)
  // We can now store our encrypted result and our nonce somewhere
  return `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`
}

export const getLocationService = (): Promise<LocationDto> => {
  if(MOCK) {
    const mock: LocationDto = {
      lastUpdate: "2022-08-27T21:06:37Z", 
      xCoordinate: {
        value: -30.59152412,
        visible: true
      },
      yCoordinate: {
        visible: true,
        value: 25.40337563,
      },
      altitude: {
        visible: true,
        value: 1202,
      },
      distanceDriven: {
        visible: true,
        value: 3302,
      },
      Odo: {
        visible: true,
        value: 344,
      },
      loops: {
        visible: true,
        value: 0
      }
    }
    return new Promise((resolve, reject) => {
      resolve(mock);
    })
  }

  let result = encrypt_data('requesting_location_data')
  return AxiosInstance.get<LocationDto>(`location`, {
      'headers': {
        'Authorization': 'token ' + result
      }
    }
  ).then((response) => response.data);
};

export const getMetricsService = (): Promise<MetricsDto> => {
  if(MOCK) {
    const mock: MetricsDto = {
      lastUpdate: "2022-08-18T09:36:37Z",
      solarPower: {
        visible: true,
        value: "high",
      },
      temperature: {
        visible: true,
        value: 26,
      },
      windSpeed: {
        visible: true,
        value: 34,
      }
    }
    return new Promise((resolve, reject) => {
      resolve(mock);
    })
  }

  let result = encrypt_data('requesting_metric_data')
  return AxiosInstance.get<MetricsDto>(`metrics`, {
      'headers': {
        'Authorization': 'token ' + result
      }
    }
  ).then((response) => response.data);
};

export const getStartTimeService = (dayNumber?: number | null): Promise<StartTimeDto> => {
  if(MOCK) {
    const mock: StartTimeDto = {
      startTime: "2022-08-29T06:00:00Z",
      dayNumber: 1
    };

    return new Promise((resolve, reject) => {
      resolve(mock);
    });
  }

  let result = encrypt_data('requesting_start_time')
  return AxiosInstance.get<StartTimeDto>(`starttime`, {
    params: {
      daynumber: dayNumber,
    },
    'headers': {
        'Authorization': 'token ' + result
      }
    }
  ).then((response) => response.data);
};

export const getStatusService = (): Promise<StatusDto> => {
  if(MOCK) {
    const mock: StatusDto = {
      // status: "before"
      status: "driving"
      // status: "night"
      // status: "offline"
      // status: "finished"
    };

    return new Promise((resolve, reject) => {
      resolve(mock);
    });
  }

  let result = encrypt_data('requesting_status')
  return AxiosInstance.get<StatusDto>(`status`, {
    'headers': {
        'Authorization': 'token ' + result
      }
    }
  ).then((response) => response.data);
};

export const getDriverService = (): Promise<DriverDto> => {
  if(MOCK) {
    const mock: DriverDto = {
      lastUpdate: '2022-08-29T19:30:16Z',
      driver: "ruben",
      visible: true
    };

    return new Promise((resolve, reject) => {
      resolve(mock);
    });
  }

  let result = encrypt_data('requesting_driver_information')
  return AxiosInstance.get<DriverDto>(`driver`, {
    'headers': {
        'Authorization': 'token ' + result
      }
    }
  ).then((response) => response.data);
};

export const getRankingService = (): Promise<RankingDto> => {
  if(MOCK) {
    const mock: RankingDto = {
      lastUpdate: '2022-08-16T19:08:16',
      ranking: ['agoria', 'brunel', '?'],
      visible: true,
    };
    return new Promise((resolve, reject) => {
      resolve(mock);
    });
  }

  let result = encrypt_data('requesting_ranking_information')
  return AxiosInstance.get<RankingDto>(`ranking`, {
    'headers': {
        'Authorization': 'token ' + result
      }
    }
  ).then((response) => response.data);
};
