import OfflinePlaceholder from '../../OfflinePlaceholder/OfflinePlaceholder';
import Timestamp from '../../Timestamp/Timestamp';
import './GeneralTile.scss';

interface GeneralTileProps {
  className: string;
  label: string;
  timestamp?: string;
  value?: number | string;
  unit?: string;
  visible?: boolean;
}

function GeneralTile(props: GeneralTileProps) {
  const isTileDataLoading = () => {
    return props.visible === undefined 
      && props.value === undefined;
  }

  const isTileOffline = () => {
    return !props.visible && !isTileDataLoading();
  }

  const getTemplate = () => {
    if (isTileDataLoading()) {
      return <div className="c-tile-value -placeholder"/>;
    }
    if (props.value && props.visible) {
      return <div className='c-tile-value'>{props.value} {props.unit}</div>
    }
    return <div className='c-tile-value'>- {props.unit}</div>;
  }

  return (
    <div className={"general-tile " + props.className} tabIndex={0}>
      <div className='c-tile-title'>{props.label}</div>
      { isTileOffline() ? <OfflinePlaceholder /> : <Timestamp timestamp={props.timestamp} />}
      { getTemplate() }
    </div>
  );
}
export default GeneralTile;
