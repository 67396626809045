import './OfflineTileLayout.scss';
import SocialMedia from '../../SocialMedia/SocialMedia';

interface OfflineTileLayoutProps {
}

function OfflineTileLayout(props: OfflineTileLayoutProps) {
    return (
        <div className="c-offline-tile-layout">
            <h1>Oh no!</h1>
            <p>Our race dashboard is currently offline.</p>
            <p>Please refer to our <a href="https://press.solarteam.be/"><span className="-underline-on-hover">press page</span></a> or our social media for updates.</p>
            <SocialMedia />
            
        </div>
    ); 
}
export default OfflineTileLayout;
