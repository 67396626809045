import "./NightTileLayout.scss";

import { LocationDto } from "../../../models/LocationDto";
import { RankingDto } from "../../../models/RankingDto";
import Countdown from "../../Countdown/Countdown";
import MapTile from "../../Tiles/MapTile/MapTile";
import RankingTile from "../../Tiles/RankingTile/RankingTile";
import DailyRecapTile from "../../Tiles/DailyRecapTile/DailyRecapTile";

interface NightTileLayoutProps {
    raceDayNumber: number | null;
    location: LocationDto | null;
    ranking: RankingDto | null;
    startTime: string | null;
}

function NightTileLayout(props: NightTileLayoutProps) {

    return (
        <div className="c-dashboard c-night-tile-layout">
            <div className="c-tiles">
                <div className="-countdown" tabIndex={0}>
                    <div className='c-tile-title'>Start of next race day</div>
                    <Countdown 
                        countdownTo={props.startTime}
                        showDays={false}
                        darkPlaceholder={false} />
                </div>
                <MapTile // TODO: verify what is shown after end of day
                    raceDayNumber={props.raceDayNumber}
                    lastUpdate={props.location?.lastUpdate}
                    xCoordinate={props.location?.xCoordinate.value}
                    yCoordinate={props.location?.yCoordinate.value}
                    visible={props.location?.xCoordinate.visible && props.location?.yCoordinate.visible }
                    showFullRoute={true}
                />
                <RankingTile
                    lastUpdate={props.ranking?.lastUpdate}
                    ranking={props.ranking?.ranking}
                    visible={props.ranking?.visible}
                />
                <DailyRecapTile />
            </div>
        </div>
    ); 
}
export default NightTileLayout;
