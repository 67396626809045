import './BeforeTileLayout.scss';

import Countdown from '../../Countdown/Countdown';
import SocialMedia from '../../SocialMedia/SocialMedia';
import MapTile from '../../Tiles/MapTile/MapTile';

interface BeforeTileLayoutProps {
}

function BeforeTileLayout(props: BeforeTileLayoutProps) {
    return (
        <div className="c-dashboard c-before-tile-layout">
            <p>Time until race start:</p>
            <Countdown 
                countdownTo="2022-09-09T07:00:00Z" 
                showDays={true} 
                darkPlaceholder={true} />

            <div className="c-social-media__title">Follow our race preparations on our social media:</div>
            <SocialMedia />
            <div className='c-tiles'>
                <MapTile 
                    raceDayNumber={-1} 
                    showFullRoute={true}
                />
            </div>
        </div>
    ); 
}
export default BeforeTileLayout;
