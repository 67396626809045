export const day1: [number, number][] = [
    [-26.26003838,28.31417656],
    [-26.26047325,28.31443214],
    [-26.26046181,28.31502724],
    [-26.25885200999999,28.32215309],
    [-26.25852203,28.32229424],
    [-26.25656891,28.32146835],
    [-26.25585365,28.32073021],
    [-26.25576401,28.32057953],
    [-26.25276566,28.30348206],
    [-26.25209236,28.28680611],
    [-26.25167465000001,28.24825668],
    [-26.25173378,28.20720291],
    [-26.25774765,28.17620468],
    [-26.25670815,28.17323112],
    [-26.25345039,28.16986847],
    [-26.2496109,28.1662178],
    [-26.2483139,28.16322517],
    [-26.24808311,28.16049957],
    [-26.24834633,28.15856361],
    [-26.24889946,28.15519713999999],
    [-26.25164032,28.1419239],
    [-26.25096512,28.13953018],
    [-26.24563217,28.1307354],
    [-26.24510574,28.12572098],
    [-26.24551773,28.1246109],
    [-26.24772644,28.12336922],
    [-26.25505829,28.12151718],
    [-26.25712395,28.11989212],
    [-26.25832748,28.11757469],
    [-26.26261711,28.10234261],
    [-26.26425934,28.0977993],
    [-26.26530457,28.09716797],
    [-26.26649666,28.09722328],
    [-26.26751518,28.09802246],
    [-26.26942253,28.10139465],
    [-26.27375984,28.10388184],
    [-26.27648544,28.10435486],
    [-26.27928734,28.10355759],
    [-26.28346443,28.10157394000001],
    [-26.28613853,28.10162163],
    [-26.28732681,28.10205841],
    [-26.30648613,28.1121769],
    [-26.31747818,28.11263275],
    [-26.32767677,28.11229515],
    [-26.34908485,28.10132217],
    [-26.38167,28.08810616],
    [-26.41657829,28.07752228],
    [-26.43978882,28.07386971],
    [-26.45059204,28.07216263],
    [-26.46445465,28.06938934],
    [-26.48451424,28.05661392],
    [-26.51723099,28.03680992],
    [-26.54246712,28.00872993],
    [-26.56437111,27.99798775],
    [-26.56775093,27.99798012],
    [-26.57351112,27.99943352],
    [-26.57438469,27.99936295],
    [-26.59235001,27.99037361],
    [-26.60717392,27.98227882],
    [-26.61904716,27.97204208],
    [-26.63640594,27.95176888],
    [-26.64962959,27.93421364],
    [-26.65485573,27.92099571],
    [-26.65749741,27.91704559],
    [-26.67224121,27.90691185],
    [-26.68776321,27.90487099],
    [-26.70583725,27.89949989],
    [-26.72049522,27.90019226],
    [-26.72426796,27.89921188],
    [-26.74151039,27.89091492],
    [-26.74375343,27.88830948],
    [-26.749403,27.87871361],
    [-26.75776291,27.87119102],
    [-26.77236557,27.86590958],
    [-26.78292084,27.86232376],
    [-26.78427124,27.86085129],
    [-26.78494835,27.85898209],
    [-26.78541946,27.84449196],
    [-26.78562737,27.84402847],
    [-26.78713036,27.84260559],
    [-26.78736496,27.84176254],
    [-26.78767204,27.84166145],
    [-26.79504395,27.84363747],
    [-26.79537201,27.84405518],
    [-26.79582214,27.84383392],
    [-26.7961998,27.84239769],
    [-26.79733467,27.8400383],
    [-26.80137825,27.8317337],
    [-26.80313301,27.83081627],
    [-26.80421829,27.8309536],
    [-26.80477524,27.83045197],
    [-26.80444527,27.82979584],
    [-26.79982376,27.82876205],
    [-26.7988739,27.82808685],
    [-26.79668236,27.82539558],
    [-26.79641533,27.82459259],
    [-26.79439354,27.82414627],
    [-26.79416800356656,27.8247737356506],
    [-26.79546928,27.82464027],
    [-26.795784,27.82474899],
    [-26.80027771,27.82912254],
    [-26.80357361,27.82977676],
    [-26.80356407,27.83022118],
    [-26.80311966,27.83068848],
    [-26.80000687,27.83165169],
    [-26.79818535,27.83104706],
    [-26.79030991,27.82612419],
    [-26.78822899,27.82173729],
    [-26.78778076,27.82135773],
    [-26.78764725,27.82112885],
    [-26.80007172,27.80181503],
    [-26.81853104,27.77343941],
    [-26.83156776,27.74501801],
    [-26.84387207,27.71325493000001],
    [-26.84890556,27.67973518000001],
    [-26.84863281,27.64503098],
    [-26.84976578,27.62832069],
    [-26.84792328,27.62840652],
    [-26.84692192,27.62895012],
    [-26.84648895,27.62933731],
    [-26.84639931,27.6294117],
    [-26.84627533,27.6296711],
    [-26.84687996,27.62904549],
    [-26.84763527,27.62854767],
    [-26.8495369,27.62828827],
    [-26.84968948,27.62837791],
    [-26.84860802,27.64116096],
    [-26.8488121,27.67265511],
    [-26.84562302,27.70497322],
    [-26.84502411,27.70773125],
    [-26.83345413,27.74019241],
    [-26.81975746,27.77152252],
    [-26.80009079,27.80178261],
    [-26.78581429,27.82430649],
    [-26.78528404,27.82746887],
    [-26.78531837,27.82811928],
    [-26.78546143000001,27.82814789],
    [-26.78702545,27.82784271],
    [-26.7888279,27.82673264],
    [-26.78991318,27.82674789],
    [-26.80110931,27.83345222],
    [-26.80132866,27.83372116],
    [-26.8015213,27.8344574],
    [-26.8012886,27.83518028],
    [-26.80068207,27.83561516],
    [-26.80002975,27.83526993],
    [-26.80002022,27.83453369],
    [-26.80136299,27.83175087],
    [-26.80312729,27.83081627],
    [-26.80426407,27.83094025],
    [-26.80477524,27.83035851],
    [-26.80425835,27.82972717000001],
    [-26.79990959,27.82881546],
    [-26.79895592,27.82817268],
    [-26.79670334,27.82544327],
    [-26.79641914,27.82460785],
    [-26.79495239,27.82431602],
    [-26.79441261,27.82414436],
    [-26.79426764999999,27.82440948],
    [-26.79418073698427,27.82476906361002],
    [-26.79546678678449,27.82466600146526],
    [-26.79585075,27.82479095],
    [-26.79987716999999,27.82892418],
    [-26.80357361,27.82977676],
    [-26.80365181,27.8299675],
    [-26.80345535,27.8303833],
    [-26.80283737,27.83078957],
    [-26.80212402,27.83103752],
    [-26.80003738,27.83164215],
    [-26.79789925,27.83087348999999],
    [-26.79033661,27.82614708],
    [-26.78817368,27.82168387999999],
    [-26.78761101,27.82119751],
    [-26.78759193,27.82117081],
    [-26.80213165,27.79869461],
    [-26.81898499,27.77279663],
    [-26.83185768,27.74430466],
    [-26.84331322,27.71492767],
    [-26.84890556,27.68897247],
    [-26.84892845,27.6825695],
    [-26.84867287,27.64789391],
    [-26.84862328000001,27.63918495],
    [-26.85637474,27.6353054],
    [-26.8572216,27.63492393],
    [-26.88808632,27.62298583999999],
    [-26.92160606,27.6084156],
    [-26.95437431,27.59166718],
    [-26.9871006,27.57492828],
    [-27.02105331,27.56159019],
    [-27.05548096,27.54974747],
    [-27.08991051,27.53790665],
    [-27.12448883,27.52599716],
    [-27.16000938,27.51375771],
    [-27.19552612,27.50151634],
    [-27.23234558,27.4964447],
    [-27.25784683,27.49218178],
    [-27.26329231,27.48918343],
    [-27.28507805,27.47380447],
    [-27.31025314,27.45600891],
    [-27.33198357,27.44064713],
    [-27.3533287,27.42555237],
    [-27.38203812,27.40522003],
    [-27.4082489,27.38687325],
    [-27.43240547,27.37015724],
    [-27.43400574,27.36903954],
    [-27.45439339,27.35492706],
    [-27.47965431,27.33739853],
    [-27.50263405,27.32147598],
    [-27.52654076,27.30491447],
    [-27.5549469,27.28524208],
    [-27.58634949,27.27056885],
    [-27.61647415,27.25737],
    [-27.61973381,27.25619888],
    [-27.62123871,27.25645447],
    [-27.62268448,27.2569561],
    [-27.6248951,27.25671768],
    [-27.62247276,27.24294472],
    [-27.62128639,27.23752975],
    [-27.62013435,27.23605156],
    [-27.61868858,27.23522186],
    [-27.61795807,27.23506546],
    [-27.61803818,27.23459435],
    [-27.61880875,27.23389435],
    [-27.62552643,27.23293686],
    [-27.62793922,27.2330761],
    [-27.63181686,27.23334312],
    [-27.63225555,27.23356819],
    [-27.63202286642712,27.23821158972557],
]
