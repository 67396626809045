import './Countdown.scss';
import { intervalToDuration } from "date-fns";
import { useState } from "react";
import useInterval from '../../services/UseInterval';

interface CountdownProps {
    countdownTo: string | null;
    showDays: boolean;
    darkPlaceholder: boolean;
}

function Countdown(props: CountdownProps) {
    const [countdownTime, setCountdownTime] = useState <Duration|null>(null); 

    const _updateIntervalInMs = 1000; 

    useInterval (() => {
        calculateCountdown();
    }, _updateIntervalInMs)

    const calculateCountdown = () => { // TODO
        if(props.countdownTo) {
            let duration = intervalToDuration({
                start: new Date(), 
                end: new Date(props.countdownTo),
            })
            
            setCountdownTime(duration);
        }
    }

    const placeholder = <div className={"c-countdown__number -placeholder " + (props.darkPlaceholder ? "-dark" : "loader")} />;

    return (
        <div className="c-countdown">
            { props.showDays ? 
                <div>
                    {countdownTime ? 
                        <div className="c-countdown__number">{countdownTime.days}</div>
                        :
                        placeholder
                    }
                    <div className="c-countdown__unit">{countdownTime?.days === 1 ? "Day" : "Days"}</div>
                </div>
                :
                null
            }
            <div>
                {countdownTime ? 
                    <div className="c-countdown__number">{countdownTime.hours}</div>
                    :
                    placeholder
                }
                <div className="c-countdown__unit">{countdownTime?.hours === 1 ? "Hour" : "Hours"}</div>
            </div>
            <div>
                {countdownTime ? 
                    <div className="c-countdown__number">{countdownTime.minutes}</div>
                    :
                    placeholder
                }
                <div className="c-countdown__unit">{countdownTime?.minutes === 1 ? "Min" : "Mins"}</div>
            </div>
            <div>
                {countdownTime ? 
                    <div className="c-countdown__number">{countdownTime.seconds}</div>
                    :
                    placeholder
                }
                <div className="c-countdown__unit">Sec</div>
            </div>
        </div>
    ); 
}
export default Countdown;
