import GeneralTile from '../GeneralTile/GeneralTile';

interface WindTileProps {
  lastUpdate?: string;
  windSpeed?: number;
  visible?: boolean;
}

function WindTile(props: WindTileProps) {
  return (
    <GeneralTile
      className="-wind" 
      label="Wind speed"
      timestamp={props.lastUpdate}
      value={props.windSpeed}
      unit="km/h"
      visible={props.visible}
    />
  ); 
}
export default WindTile;
