import './FinishedTileLayout.scss';

import { RankingDto } from "../../../models/RankingDto";
import SocialMedia from "../../SocialMedia/SocialMedia";
import DailyRecapTile from "../../Tiles/DailyRecapTile/DailyRecapTile";
import RankingTile from "../../Tiles/RankingTile/RankingTile";

interface FinishedTileLayoutProps {
    ranking: RankingDto | null;
}

function FinishedTileLayout(props: FinishedTileLayoutProps) {
  return (
    <div className="c-dashboard c-finished-tile-layout">
        <h1>The race has ended!</h1>
        <p>You can find the final ranking and our daily recap video's below.</p>
        <p>For more information, please refer to our <a href="https://press.solarteam.be/"><span className="-underline-on-hover">press page</span></a>.</p>
        <SocialMedia />
        <div className="c-tiles">
            <RankingTile
                lastUpdate={props.ranking?.lastUpdate}
                ranking={props.ranking?.ranking}
                visible={props.ranking?.visible}
            />
            <DailyRecapTile />
        </div>
    </div>
  ); 
}
export default FinishedTileLayout;
