import GeneralTile from '../GeneralTile/GeneralTile';

interface SolarTileProps {
  lastUpdate?: string;
  solarPower?: string;
  visible?: boolean;
}

const capitalizeFirstLetter = (string?: string) => {
  if(!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function SolarTile(props: SolarTileProps) {
  return (
    <GeneralTile
      className="-solar" 
      label="Solar power"
      timestamp={props.lastUpdate}
      value={capitalizeFirstLetter(props.solarPower)}
      visible={props.visible}
    />
  ); 
}

export default SolarTile;
